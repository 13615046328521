<template>
  <div class="client-configuration">
    <label>Redirection to the original internet source?</label>
    <input type="checkbox"
    v-model="isRedirectionAllowed"
    @change="changeStatus" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'ClientConfiguration',
  data () {
    return {
      isRedirectionAllowed: false
    }
  },

  computed: {
    ...mapState('clients', ['clientDetails'])

  },
  watch: {
    clientDetails () {
      this.isRedirectionAllowed = this.clientDetails.original_source_redirection
    }
  },
  methods: {
    ...mapActions('clients', ['updateClientDetails']),
    changeStatus: function () {
      const client = {
        clientId: this.$route.params.id,
        data:
          {
            'original_source_redirection': this.isRedirectionAllowed
          }
      }
      this.updateClientDetails(client)
    }
  }
}
</script>

<style lang="scss" scoped>
.client-configuration {
  display: flex;
  width: 100%;
  margin-top: 20px;

  label {
    position: sticky;
    font-size: 16px;
    color: var(--secondary-text-color);
    padding: 0.75rem 1rem;
  }
  input{
  margin-left: 14px;
  transform : scale(1.2);
  cursor: pointer;
  }
}
</style>
