<template>
  <!-- <modal-vue @onChange="onChangeModal" :modal="modal"> -->
  <div class="new-clients-container">
    <admin-header-vue
      alias=""
      :close="true"
      pageName="New Subscription"
      @onClose="onClose" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column is-6">
            <div class="form-group">
              <p class="display-flex">
                Select Categories <sup class="astrik">*</sup>
              </p>
              <dropdown-wrap-vue @onClickOutside="onClickOutsideCategory">
                <div class="relative">
                  <b-input
                    type="search"
                    ref="categories"
                    @focus="onFocusCategory"
                    :icon-right="openCategory ? 'menu-up' : 'menu-down'"
                    v-model="searchCateogry"
                    class="form-control" />
                  <div
                    v-if="submitted && !newSubscription.categoryId.length"
                    class="required-error">
                    Category Name is required.
                  </div>
                  <common-selection
                    :searchText="searchCateogry"
                    name="category_name"
                    @focus="onFocusCategory"
                    :open="openCategory"
                    :selected="newSubscription.categoryId"
                    @onChange="onChangeCategoryId"
                    :list="categoryList" />
                </div>
                <closable-tags
                  @onChange="onRemoveCategoryName"
                  name="category_name"
                  :selected="newSubscription.categoryId" />
              </dropdown-wrap-vue>
            </div>
          </div>
          <div class="column flex is-6">
            <div class="toggle">
              <ToggleButtonVue
                :toggle="newSubscription.displayCategoryHierarchy"
                :disabled="newSubscription.categoryId.length<2"
                label="Show Group Category"
                @onChange="onChangeDisplayCategoryHierarchy" />
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <input-vue
              :value="newSubscription.categoryAlias"
              :required="true"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeCategoryAlias"
              label="Category Alias" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <div class="form-group">
              <p class="display-flex">
                Select Products <sup class="astrik">*</sup>
              </p>
              <dropdown-wrap-vue @onClickOutside="onClickOutside">
                <div class="relative">
                  <b-input
                    type="search"
                    ref="products"
                    @focus="onFocus"
                    :icon-right="openProduct ? 'menu-up' : 'menu-down'"
                    v-model="searchProduct"
                    class="form-control" />
                  <div
                    v-if="submitted && !newSubscription.productId.length"
                    class="required-error">
                    Product Name is required
                  </div>
                  <common-selection
                    :searchText="searchProduct"
                    name="product_name"
                    @focus="onFocus"
                    :open="openProduct"
                    :selected="newSubscription.productId"
                    @onChange="onChangeProductId"
                    :showDataLink="true"
                    dataLinkLabel="category_name"
                    :list="productListByCategory" />
                </div>
                <closable-tags
                  @onChange="onRemoveProductName"
                  name="product_name"
                  :showDataLink="true"
                  dataLinkLabel="category_name"
                  :selected="newSubscription.productId" />
              </dropdown-wrap-vue>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <div class="form-group">
              <p class="display-flex">
                Select Data Sources <sup class="astrik">*</sup>
              </p>
              <dropdown-wrap-vue @onClickOutside="onClickOutsideSource">
                <div class="relative">
                  <b-input
                    type="search"
                    ref="sources"
                    @focus="onFocusSource"
                    v-model="searchSource"
                    class="form-control" />
                  <div
                    v-if="submitted && !newSubscription.sourceId.length"
                    class="required-error">
                    Data Source Name is required
                  </div>
                  <common-selection
                    :searchText="searchSource"
                    name="source_name"
                    @focus="onFocusSource"
                    :open="openSource"
                    :selected="newSubscription.sourceId"
                    @onChange="onChangeSourceId"
                    :list="dataSourceList" />
                </div>
                <closable-tags
                  @onChange="onRemoveSourceName"
                  name="source_name"
                  :selected="newSubscription.sourceId" />
              </dropdown-wrap-vue>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <select-vue
              :value="dataSubscriptionType"
              :required="true"
              label="Data Subscription"
              :submitted="submitted"
              placeholder="Select a data subscription"
              :list="dataSubscriptionOptions"
              @onChangeSelection="onChangeDataSubscriptionOption"
              id="id"
              name="type" />
          </div>
          <div class="column is-6">
            <select-vue
              :value="newSubscription.monthGranularity"
              :required="true"
              label="Granularity"
              :submitted="submitted"
              placeholder="Select a granularity"
              :list="granularity"
              @onChangeSelection="onChangeGranularity"
              id="id"
              name="type" />
          </div>
        </div>
        <div
          v-if="dataSubscriptionType == 2 || dataSubscriptionType == 4"
          class="columns">
          <div class="column is-12">
            <calendar-vue
              :value="newSubscription.dateRange.start_date"
              label="Subscription Start"
              :submitted="submitted"
              :required="true"
              :max="newSubscription.dateRange.end_date"
              @onChange="onChangeStart"
              placeholder="" />
          </div>
        </div>
        <div
          v-if="dataSubscriptionType == 3 || dataSubscriptionType == 4"
          class="columns">
          <div class="column is-12">
            <calendar-vue
              :value="newSubscription.dateRange.end_date"
              label="Subscription End"
              @onChange="onChangeEnd"
              :min="newSubscription.dateRange.start_date"
              :required="true"
              :submitted="submitted"
              placeholder="" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="topic-options">
              Topic<sup class="display-flex required">*</sup>
            </div>
            <div class="align-options">
              <div
                class="option-cover"
                v-for="(topic, index) of topics"
                :key="index">
                <radio-vue
                  :selected="newSubscription.customTopic"
                  :disable="newSubscription.categoryId.length==0 && topic==topics[1]"
                  @onChange="onChangeOption"
                  :value="topic" />
                <p  @click="onChangeOption(topic)">{{ topic.type }}</p>
              </div>
              <b-button v-if="newSubscription.customTopic==topics[1]" @click="autoAddTopics(true)" class="reset-topics"><p>Reset topics</p></b-button>
            </div>
          </div>
        </div>
        <parent-topic-bucket-vue
          :topicList="newSubscription.parentTopicList"
          :categoryCustomTopics="parentTopics"
          :submitted="submitted"
          @onRemoveTopics="onRemoveTopics"
          @addNewTopic="addNewParentTopic"
          @onUpdateTopic="onUpdateParentTopic"
          @onDelete="onDeleteParent"
          v-if="newSubscription.customTopic.id == true" />

        <topic-bucket-vue
          :parentTopicList="newSubscription.parentTopicList"
          :topicList="newSubscription.topicList"
          :categoryCustomTopics="childTopics"
          :submitted="submitted"
          @addNewTopic="addNewTopic"
          @onUpdateTopic="onUpdateTopic"
          @onDelete="onDelete"
          v-if="newSubscription.customTopic.id == true"
          :pendingChildTopics="pendingChildTopics" />
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Add</b-button>
              <b-button @click.prevent="onClose">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- </modal-vue> -->
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'
import ToggleButtonVue from '../../../../components/Common/ToggleButton.vue'
import CommonSelection from '../../../../components/Dropdowns/CommonSelection.vue'

import {
  topics,
  granularity,
  dataSubscriptionOptions
} from '../../../../constant/data'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import calendarVue from '../../components/calendar.vue'
import ClosableTags from '../../components/ClosableTags.vue'
import InputVue from '../../components/Input.vue'
import RadioVue from '../../components/Radio.vue'
// import ModalVue from '../../components/Modal.vue'
import SelectVue from '../../components/Select.vue'
import ParentTopicBucketVue from './ParentTopicBucket/ParentTopicBucket.vue'
import TopicBucketVue from './TopicBucket.vue'

/**
 * This component contains the code required to add/create a new subscription.
 */
export default {
  name: 'AddSubscription',
  components: {
    AdminHeaderVue,
    SelectVue,
    calendarVue,
    CommonSelection,
    DropdownWrapVue,
    ClosableTags,
    InputVue,
    RadioVue,
    TopicBucketVue,
    ToggleButtonVue,
    ParentTopicBucketVue
    // ModalVue
  },
  data () {
    return {
      topics,
      modal: true,
      granularity,
      dataSubscriptionOptions,
      newSubscription: {
        clientId: null,
        categoryId: [],
        productId: [],
        sourceId: [],
        dateRange: {
          start_date: null,
          end_date: null
        },
        monthGranularity: null,
        customTopic: topics[0],
        parentTopicList: [],
        categoryAlias: null,
        topicList: [],
        displayCategoryHierarchy: false
      },
      dataSubscriptionType: null,
      searchProduct: null,
      searchCateogry: null,
      searchSource: null,
      openProduct: false,
      openCategory: false,
      openSource: false,
      isDataSubscriptionTypeValid: false,
      isStarSubscriptionDateValid: false,
      isEndSubscriptionDateValid: false,
      isClientIdValid: false,
      isCategoryIdValid: false,
      isGranularityValid: false,
      isCategoryAliasValid: false,
      pendingChildTopics: [],
      submitted: false
    }
  },
  computed: {
    ...mapState('clients', ['clientList']),
    ...mapState('categories', ['categoryList']),
    ...mapState('dataSource', ['dataSourceList']),
    ...mapState('products', ['productListByCategory']),
    ...mapState('topics', ['parentTopics', 'childTopics']),
    ...mapState('user', ['userDetails'])
  },
  watch: {
    'newSubscription.categoryId' () {
      if (this.newSubscription.categoryId.length === 0) {
        this.resetParentTopicList()
        this.resetChildTopicList()
        this.newSubscription.customTopic = topics[0]
      }
      if (this.newSubscription.categoryId.length < 2) {
        this.newSubscription.displayCategoryHierarchy = false
      }
      if (this.newSubscription.categoryId.length === 1) {
        this.newSubscription.categoryAlias = this.newSubscription.categoryId[0].category_name
      } else {
        this.newSubscription.categoryAlias = null
      }
    },
    parentTopics () {
      this.autoAddTopics()
    },
    childTopics () {
      this.pendingChildTopics = this.childTopics.map((data) => {
        return {
          id: data.topic_id,
          name: data.topic_name,
          used: true,
          category: data.category_name
        }
      })
      this.autoAddTopics()
    }
  },
  mounted () {
    this.getClientList()
    this.getCategoryList()
    // this.getDataSourceList(this.userDetails.modules.filter(m => m.module_name === 'voc')[0].module_id)
    this.getDataSourceList(this.userDetails.modules.find(m => m.module_name === 'voc').module_id)
    this.newSubscription.clientId = this.$route.params.id
  },
  methods: {
    ...mapActions('subscriptions', ['addSubscription']),
    ...mapActions('categories', ['getCategoryList']),
    ...mapActions('clients', ['getClientList']),
    ...mapActions('dataSource', ['getDataSourceList']),
    ...mapActions('products', [
      'getProductsByCategoryId',
      'resetProductsByCategories'
    ]),
    ...mapActions('topics', ['getTopicListByCategoryId', 'resetParentTopicList', 'resetChildTopicList']),
    // onChangeModal (value) {
    //   this.modal = value
    //   if (!this.modal) {
    //     this.$router.replace(`/admin/client/${this.$route.params.id}/update`)
    //   }
    // },
    /** This method is called when a user clicks on the *Standard + Custom* option in the *Create Subscription* modal. It internally calls the *autoAddTopics()* method.
     * @param value {Object} - This object controls whether or not the subscription will have just standard or custom topics
     * @public
     */
    onChangeOption (value) {
      if (!(this.newSubscription.categoryId.length === 0 && value === topics[1])) {
        this.newSubscription.customTopic = value
        this.autoAddTopics()
      }
    },
    /** This method automatically creates the *Functionality* parent topic row, and populates it with the functionality topics of all the Categories selected. It calls the *generateDefaultData()* method internally
     * @param type - Unsure what value goes here, if any
     * @public
     */
    autoAddTopics (type) {
      if (this.newSubscription.customTopic.id && this.parentTopics.length &&
        this.childTopics.length && this.newSubscription.categoryId.length &&
        ((!this.newSubscription.parentTopicList.length && !this.newSubscription.topicList.length) || type)) {
        this.generateDefaultData()
      }
    },
    /** This method removes a topic from the dropdown when a user clicks on the X button on a topic tag present under the multiselect search dropdown
     * @param object {Object} - The topic to be deselected/removed
     * @public
     */
    onRemoveTopics (object) {
      const child = []
      for (const topic of this.newSubscription.topicList) {
        topic.topicIds = topic.topicIds.filter(
          (id) => Number(id.category_id) !== object.category_id
        )
        if (topic.topicIds.length) {
          child.push(topic)
        }
      }
      this.newSubscription.topicList = child
      if (!this.newSubscription.topicList.length) {
        this.newSubscription.topicList = [{ topicAlias: null, topicIds: [], isNew: true }]
      }
    },
    /** This method creates modifiable children topics from the Functionality topics of all the categories selected in the subscription. Each of those children topics is given an alias. Two child topics can be generated having the same alias. This happens when there were Functionlity topics for two different categories with the same child topic names. In this case the user can opt to leave them separate, or manually merge the two under one name.
     * @public
     */
    generateDefaultData () {
      this.newSubscription.parentTopicList = []
      this.newSubscription.topicList = []
      this.newSubscription.parentTopicList = [{ topicAlias: this.parentTopics[0].topic_name, index: 0, topicIds: this.parentTopics, isNew: false }]
      const temp = []
      for (const parent of this.newSubscription.parentTopicList) {
        for (const child of this.childTopics) {
          temp.push({ topicAlias: child.topic_name, topicIds: [child], parentGroup: parent, isNew: false })
        }
      }
      this.newSubscription.topicList = temp
      this.storePendingChildTopics()
    },
    /** This method creates a new child topic row
     * @param object {Object}
     * @public
     */
    addNewTopic (object) {
      if (this.newSubscription.categoryId.length) {
        const topicListLength = this.newSubscription.topicList.length
        if (topicListLength > 0) {
          if ((this.newSubscription.topicList[topicListLength - 1].topicAlias == null ||
            this.newSubscription.topicList[topicListLength - 1].topicAlias === '') ||
            this.newSubscription.topicList[topicListLength - 1].topicIds.length === 0) {
            return
          }
        }
        this.newSubscription.topicList.push(object)
      }
    },
    /** This method renders a new **SingleParentTopicRow** component
     * @param object {Object}
     * @public
     */
    addNewParentTopic (object) {
      if (this.newSubscription.categoryId.length) {
        const parentTopicListLength = this.newSubscription.parentTopicList.length
        if (parentTopicListLength > 0) {
          if ((this.newSubscription.parentTopicList[parentTopicListLength - 1].topicAlias == null ||
            this.newSubscription.parentTopicList[parentTopicListLength - 1].topicAlias === '') ||
            this.newSubscription.parentTopicList[parentTopicListLength - 1].topicIds.length === 0) {
            return
          }
        }
        this.newSubscription.parentTopicList.push(object)
      }
    },
    /** This method is called when the *Show Group Category* switch is toggled. If it is set to *true*, then the new custom category will be expandable and a user will be able to see all the standard categories that it is made of. If the value of the switch is set to *false* the user will only be able to see a non-expandable view of the custom category.
     * @param val - true/false
     * @public
     */
    onChangeDisplayCategoryHierarchy (val) {
      this.newSubscription.displayCategoryHierarchy = val
    },
    /** This method emits a *close* event, which triggers the closing of the modal.
     * @public
     */
    onClose () {
      this.$emit('close')
    },
    /** This method updates the values of the children topics. This method calls the *storePendingChildTopics* method internally.
     * @param object {Object} - The object containing the information about this particular child topic
     * @param index {Number} - The index of the child topic
     * @public
     */
    onUpdateTopic (object, index) {
      if (this.newSubscription.topicList[index]) {
        Object.assign(this.newSubscription.topicList[index], object)
      }
      this.storePendingChildTopics()
    },
    /** This method tracks what topics have been selected for the custom child topics
     * @public
     */
    storePendingChildTopics () {
      if (this.childTopics.length) {
        let temp = []
        for (const child of this.newSubscription.topicList) {
          if (child.topicIds.length) {
            temp = [...new Set([...temp, ...child.topicIds.map(obj => obj.topic_id)])]
          }
        }
        const list = this.pendingChildTopics
        if (temp.length) {
          for (const topic of list) {
            const check = temp.filter(id => id === topic.id)
            topic.used = !!check.length
          }
          this.pendingChildTopics = list.map(data => data)
        } else {
          this.pendingChildTopics = list.map(data => { data.used = false; return data })
        }
      }
    },
    /** This method updates the values of the parent topics. This method calls the *storePendingChildTopics* method internally.
     * @param object {Object} - The object containing the information about this particular parent topic
     * @param index {Number} - The index of the parent topic
     * @public
     */
    onUpdateParentTopic (object, index) {
      if (this.newSubscription.parentTopicList[index]) {
        this.newSubscription.parentTopicList.splice(index, 1, object)
      }
      this.storePendingChildTopics()
    },
    /** This method is called when the *All time, fixed start date* option is selected from the data subscription dropdown. The first time it is selected, it makes a date input visible, and passes a null value to it. Each time a date is selected, this method is called again
     * @param date {Date} - The start date
     * @param isValid {Boolean} - Indicates whether this option has been selected or not
     * @public
     */
    onChangeStart (date, isValid) {
      this.newSubscription.dateRange.start_date = date
      this.isStarSubscriptionDateValid = isValid
    },
    /** This method is called when the *All time, fixed end date* option is selected from the data subscription dropdown. The first time it is selected, it makes a date input visible, and passes a null value to it. Each time a date is selected, this method is called again
     * @param date {Date} - The start date
     * @param isValid {Boolean} - Indicates whether this option has been selected or not
     * @public
     */
    onChangeEnd (date, isValid) {
      this.newSubscription.dateRange.end_date = date
      this.isEndSubscriptionDateValid = isValid
    },
    /** This method keeps track of all the categories that have been selected for this subscription
     * @param ids {Array} - An array of category ids
     * @param isValid {Boolean} - Unsure why this is used
     * @public
     */
    onChangeCategoryId (ids, isValid) {
      this.newSubscription.categoryId = ids
      this.isCategoryIdValid = isValid
      if (ids.length) {
        this.getProductsByCategoryIdFun(ids)
        this.getTopicListByCategoryId(ids.map((id) => id.category_id))
        // this.newSubscription.productId = []
      } else {
        this.resetProductsByCategories()
      }
    },
    /** This method keeps track of all the sources that have been selected for this subscription
     * @param ids {Array} - An array of source ids
     * @param isValid {Boolean} - Unsure why this is used
     * @public
     */
    onChangeSourceId (name, isValid) {
      this.newSubscription.sourceId = name
    },
    /** This method extracts all the products that belong to a group of categories and makes them available for selection.
     * @param ids - An array of category ids
     * @public
     */
    getProductsByCategoryIdFun (ids) {
      this.getProductsByCategoryId(ids.map((id) => id.category_id))
    },
    /** This method keeps track of all the products that have been selected for this subscription
     * @param ids {Array} - An array of product ids
     * @param isValid {Boolean} - Unsure why this is used
     * @public
     */
    onChangeProductId (name, isValid) {
      this.newSubscription.productId = name
    },
    /** This method keeps track of all the time granularity that has been selected for this subscription
     * @param name {Boolean} - True for monthly granularity, false for quarterly
     * @param isValid {Boolean} - Unsure why this is used
     * @public
     */
    onChangeGranularity (name, isValid) {
      this.newSubscription.monthGranularity = name
      this.isGranularityValid = isValid
    },
    /** This method keeps track of which Data subscription date range is selected in the dropdown.
     * @param name {Number} - The value/index corresponding to each option. The first option corresponds to null instead of 0. The rest of them follow a reqular sequence of numbers starting from 1
     */
    onChangeDataSubscriptionOption (name, isValid) {
      this.dataSubscriptionType = name
      this.isDataSubscriptionTypeValid = isValid
      if (this.dataSubscriptionType === 1) {
        this.newSubscription.dateRange.start_date = null
        this.newSubscription.dateRange.end_date = null
        this.isStarSubscriptionDateValid = false
        this.isEndSubscriptionDateValid = false
      } else if (this.dataSubscriptionType === 2) {
        this.newSubscription.dateRange.end_date = null
        this.isEndSubscriptionDateValid = false
      } else if (this.dataSubscriptionType === 3) {
        this.newSubscription.dateRange.start_date = null
        this.isStarSubscriptionDateValid = false
      }
    },
    /** Unsure where this is being used */
    onChangeCustomTopic (name, isValid) {
      this.newSubscription.customTopic = name
    },
    /** This method closes the category dropdown is a click is detected outside its boundary
     * @public
     */
    onClickOutsideCategory () {
      this.openCategory = false
    },
    /** This method closes the product dropdown is a click is detected outside its boundary
     * @public
     */
    onClickOutside () {
      this.openProduct = false
    },
    /** This method closes the data sources dropdown is a click is detected outside its boundary
     * @public
     */
    onClickOutsideSource () {
      this.openSource = false
    },
    /** This method opens the product dropdown is a click is detected inside its boundary
     * @public
     */
    onFocus () {
      this.$refs.products.$el.firstChild.focus()
      this.openProduct = true
    },
    /** This method opens the category dropdown is a click is detected inside its boundary
     * @public
     */
    onFocusCategory () {
      this.$refs.categories.$el.firstChild.focus()
      this.openCategory = true
    },
    /** This method opens the data sources dropdown is a click is detected inside its boundary
     * @public
     */
    onFocusSource () {
      this.$refs.sources.$el.firstChild.focus()
      this.openSource = true
    },
    /** This method assigns a new value to the category alias.
     * @name {String} - The new category alias
     * @public
     */
    onChangeCategoryAlias (name, isValid) {
      this.newSubscription.categoryAlias = name
      this.isCategoryAliasValid = isValid
    },
    /** This method removes a product from the dropdown when a user clicks on the X button on a product tag present under the multiselect search dropdown
     * @param object {Object} - The product to be deselected/removed
     * @public
     */
    onRemoveProductName (object) {
      this.newSubscription.productId = this.newSubscription.productId.filter(
        (id) => id.product_id !== object.product_id
      )
    },
    /** This method removes a category from the dropdown when a user clicks on the X button on a category tag present under the multiselect search dropdown
     * @param object {Object} - The category to be deselected/removed
     * @public
     */
    onRemoveCategoryName (object) {
      this.newSubscription.categoryId = this.newSubscription.categoryId.filter(
        (id) => id.category_id !== object.category_id
      )
      this.newSubscription.productId = this.newSubscription.productId.filter(
        (id) => Number(id.category_id) !== object.category_id
      )
      const child = []
      const parent = []
      for (const topic of this.newSubscription.topicList) {
        topic.topicIds = topic.topicIds.filter(
          (id) => Number(id.category_id) !== object.category_id
        )
        if (topic.topicIds.length) {
          child.push(topic)
        }
      }
      this.newSubscription.topicList = child
      for (const topic of this.newSubscription.parentTopicList) {
        topic.topicIds = topic.topicIds.filter(
          (id) => Number(id.category_id) !== object.category_id
        )
        if (topic.topicIds.length) {
          parent.push(topic)
        }
      }
      this.newSubscription.parentTopicList = parent
      this.storePendingChildTopics()
    },
    /** This method removes a data source from the dropdown when a user clicks on the X button on a data source tag present under the multiselect search dropdown
     * @param object {Object} - The data source to be deselected/removed
     * @public
     */
    onRemoveSourceName (object) {
      this.newSubscription.sourceId = this.newSubscription.sourceId.filter(
        (id) => id.source_id !== object.source_id
      )
    },
    /** This method deletes a child topic row from the list
     * @param id {Number} - The id of the child row to be deleted
     */
    onDelete (object, id) {
      this.newSubscription.topicList = this.newSubscription.topicList.filter((data, index) => index !== id)
      if (!this.newSubscription.topicList.length) {
        this.newSubscription.topicList.push({ topicAlias: null, topicIds: [], isNew: true })
      }
      this.storePendingChildTopics()
    },
    /** This method deletes a parent topic row from the list
     * @param id {Number} - The id of the parent row to be deleted
     */
    onDeleteParent (object, id) {
      this.newSubscription.parentTopicList = this.newSubscription.parentTopicList.filter((data, index) => index !== id)
      if (!this.newSubscription.parentTopicList.length) {
        this.newSubscription.topicList = [{ topicAlias: null, topicIds: [], isNew: true }]
        this.newSubscription.parentTopicList.push({ topicAlias: null, topicIds: [], isNew: true })
      } else {
        this.newSubscription.topicList = this.newSubscription.topicList.filter((data) => (data.parentGroup && data.parentGroup.index !== object.index))
        if (!this.newSubscription.topicList.length) {
          this.newSubscription.topicList = [{ topicAlias: null, topicIds: [], isNew: true }]
        }
      }
      this.storePendingChildTopics()
    },
    /** This method verifies the details of the parent topic group at the time of form submission
     * @public
     */
    checkParentTopicGroup () {
      if (this.newSubscription.customTopic.id) {
        if (this.newSubscription.parentTopicList.length) {
          const check = this.newSubscription.parentTopicList.filter(
            (object) => (object.topicAlias == null || object.topicAlias === '' || !object.topicIds.length))
          if (check.length) {
            return false
          }
        } else {
          return false
        }
      }
      return true
    },
    /** This method verifies the details of the child topic group at the time of form submission
     * @public
     */
    checkTopicGroup () {
      if (this.newSubscription.customTopic.id) {
        if (this.newSubscription.topicList.length) {
          const check = this.newSubscription.topicList.filter(
            (object) => (object.topicAlias == null || object.topicAlias === '' || !object.topicIds.length))
          if (check.length) {
            return false
          }
        } else {
          return false
        }
      }
      return true
    },
    /** This method submits the form
     * @public
     */
    handleSubmit () {
      this.submitted = true
      if (this.checkParentTopicGroup() && this.checkTopicGroup() &&
        !(
          this.isStarSubscriptionDateValid ||
          this.isEndSubscriptionDateValid ||
          this.isCategoryIdValid ||
          this.isGranularityValid ||
          this.isCategoryAliasValid ||
          this.isDataSubscriptionTypeValid ||
          !this.newSubscription.productId.length ||
          !this.newSubscription.categoryId.length ||
          !this.newSubscription.sourceId.length
        )
      ) {
        const object = {
          categoryId: this.newSubscription.categoryId.map(
            (data) => data.category_id
          ),
          clientId: this.newSubscription.clientId,
          customTopic: this.newSubscription.customTopic.id,
          customTopicGroupList: this.newSubscription.topicList,
          parentTopicList: this.newSubscription.parentTopicList,
          dateRange: this.newSubscription.dateRange,
          monthGranularity: this.newSubscription.monthGranularity,
          categoryAlias: this.newSubscription.categoryAlias,
          displayCategoryHierarchy: this.newSubscription.displayCategoryHierarchy,
          productId: this.newSubscription.productId.map(
            (data) => {
              return { productId: data.product_id, categoryId: data.category_id }
            }
          ),
          sourceId: this.newSubscription.sourceId.map((data) => data.source_id)
        }
        this.addSubscription(object).then(() => {
          this.onClose()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-clients-container {
  background: var(--primary);
  border-radius: 4px;
  padding: 10px;
  .new-client {
    .columns {
      margin: 0px;
      .relative {
        position: relative;
      }
      .flex {
        display: flex;
      }
      .required-error {
        color: red;
        position: absolute;
        font-size: 12px;
      }
      .column {
        .display-flex {
          display: flex;
          margin: 0px;
          color: #7f8fa4;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          display: flex;
          line-height: 20px;
          .astrik {
            color: red;
          }
        }
        .toggle {
            display: flex;
            flex: 1;
            align-items: center;
            div {
              display: flex;
              flex: 1;
              align-items: center;
              p {
              color: var(--secondary-text-color);
              opacity: 1;
              padding-right: 10px;
              margin: 0px;
              font-family: Quicksand;
              font-size: 14px;
            }
            }
          }

        .upload {
          width: 100px;
          height: 100px;
          align-items: center;
          justify-content: center;
          border: 1px solid #dbdbdb;
          border-radius: 50%;
          img {
            width: 100px;
            height: 100px;
            object-fit: contain;
          }
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
          button:nth-child(1) {
            margin-right: 5px;
          }
          button:nth-child(2) {
            margin-left: 5px;
          }
        }
        .topic-options {
          display: flex;
          color: #7f8fa4;
          font-size: 12px;
          font-family: Quicksand;
          display: flex;
          line-height: 20px;
          .required {
            color: red;
          }
        }
        .align-options {
          padding-top: 10px;
          display: flex;
          .option-cover {
            display: flex;
            align-items: center;
            padding-right: 20px;
            cursor: pointer;
            p {
              padding-left: 10px;
              font-size: 14px;
              font-family: Quicksand;
              letter-spacing: 0px;
              color: #c8c8c8;
              opacity: 1;
            }
          }
          .reset-topics {
            border: var(--tertiary-border-color);
            padding:5px 10px;
            background: transparent;
            p {
            color: var(--secondary-text-color);
            }
          }
        }
      }
    }
  }
}
</style>
