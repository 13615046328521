<template>
    <div class="parent-bucket-container">
        <div class="columns">
        <div class="column header is-3">
            <p>Parent Topic Alias</p>
        </div>
        <div class="column header is-7">
            <p>Topics</p>
        </div>
        <div class="column header is-2">
            <p>Action</p>
        </div>
        </div>
        <div class="single-topic-row" v-for="(topic,index) of topicList" :key="index">
            <single-parent-topic-row-vue @onUpdate="onUpdate" :categoryCustomTopics="categoryCustomTopics" :submitted="submitted" @onDelete="onDeleteFun" @onRemoveTopics="onRemoveTopicsFun" :index="index" :topicDetails="topic" />
        </div>
        <b-button @click="addNewTopicFun" class="add-new-topic"><p>+ Add New Parent Alias</p></b-button>
    </div>
</template>

<script>
import SingleParentTopicRowVue from './SingleParentTopicRow.vue'

export default {
  components: {
    SingleParentTopicRowVue
  },
  props: ['topicList', 'addNewTopic', 'submitted', 'onDelete', 'onRemoveTopics', 'onUpdateTopic', 'categoryCustomTopics'],
  data () {
    return {
      topics: []
    }
  },
  mounted () {
    if (!this.topicList.length) {
      this.$emit('addNewTopic', { topicAlias: null, index: null, topicIds: [], isNew: true })
    }
  },
  methods: {
    addNewTopicFun () {
      this.$emit('addNewTopic', { topicAlias: null, index: null, topicIds: [], isNew: true })
    },
    onUpdate (object, index) {
      this.$emit('onUpdateTopic', object, index)
    },
    onDeleteFun (object, index) {
      this.$emit('onDelete', object, index)
    },
    onRemoveTopicsFun (object, id) {
      this.$emit('onRemoveTopics', object, id)
    }
  }
}
</script>

<style lang="scss">
.parent-bucket-container {
  .columns {
    .header {
      padding: 10px 0px;
      p {
        font-size: 12px;
        font-family: Quicksand;
        font-weight: 500;
        color: #EFF3F6B2;
      }
    }
    .header:nth-child(3) {
      text-align: center;
    }
    .header:nth-child(2) {
      padding-left: 12px;
    }
  }
  padding: 0px 12px;
    .single-topic-row {
        padding: 5px 0px;
    }
    .add-new-topic {
        padding: 10px 0px 0px 0px;
        height: auto;
        background-color: transparent;
        border: none;
        p {
          font-size: 14px;
          color: #3C90F5;
          font-family:'Quicksand';
        }
    }
}
</style>
