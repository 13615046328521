<template>
  <div class="clients-container">
    <modal-vue @onChange="onChangeModal" :modal="modal">
      <div class="user-delete-modal">
        <div class="user-delete-inside">
          <p class="modal-header">Delete Subscription</p>
          <div class="modal-data">
          <p>
            Are you sure you want to delete this subscription ?
          </p>
          <div class="actions">
            <b-button @click="onChangeModal(false)">Cancel</b-button>
            <b-button @click="removeSubFun">Delete</b-button>
          </div>
          </div>
        </div>
      </div>
    </modal-vue>
    <admin-header-vue v-if="showCreate && isEditable" alias="Subscription" @onCreate="onCreate" pageName="" />
    <div class="user-table">
      <table-vue
        :labels="labels"
        :dateFormat="dateFormat"
        :data="subscriptionList"
        :sortObj="sort"
        :totalCount="totalCount"
        :currentPage="currentPage"
        :limit="limit"
        :canDelete="true"
        :sortValidation="sortValidation"
        :titleData="'Subscriptions'"
        :showEditAction="showEdit"
        :showDeleteAction="showDelete"
        :showAction="(showEdit || showDelete) && isEditable "
        @searchText="searchText"
        @onUpdate="onUpdate"
        @onClick="onClick"
        @onChangeSort="onChangeSort"
        @onDelete="onDelete"
        @updatePage="updatePage"
        @updateLimit="updateLimit" />
    </div>
    <modal-vue :modal="addSubscriptionModal" @onChange="handleAddModalChange">
      <add-subscription @close="handleAddModalChange"></add-subscription>
    </modal-vue>
    <modal-vue :modal="updateSubscriptionModal" @onChange="handleUpdateModalChange">
      <update-subscription :subscriptionId="subscriptionIdToUpdate" @close="handleUpdateModalChange"></update-subscription>
    </modal-vue>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import tableVue from '../../components/table.vue'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import ModalVue from '../../components/Modal.vue'
import AddSubscription from '../Subscriptions/AddSubscription'
import UpdateSubscription from '../Subscriptions/UpdateSubscription'

export default {
  name: 'Subscriptions',
  components: {
    tableVue,
    AdminHeaderVue,
    ModalVue,
    AddSubscription,
    UpdateSubscription
  },
  props: ['isEditable'],
  data () {
    return {
      search: '',
      dateFormat: ['created_at', 'updated_at', 'start_date', 'end_date'],
      labels: [{ name: 'Categories', alias: 'category_name' }, { name: 'Products', alias: 'product_name' }, { name: 'Data Sources', alias: 'source_name' }, { name: 'Topics', alias: 'custom_topic' }, { name: 'Data Granularity', alias: 'month_granularity' }, { name: 'Start Date', alias: 'start_date' }, { name: 'End Date', alias: 'end_date' }],
      sortValidation: ['category_alias', 'client_name', 'custom_topic', 'month_granularity', 'start_date', 'end_date'],
      sort: { by: 'asc', field: 'category_name' },
      addSubscriptionModal: false,
      updateSubscriptionModal: false,
      subscriptionIdToUpdate: null,
      modal: false,
      deleteSubscriptionObject: {}
    }
  },
  computed: {
    ...mapState('subscriptions', ['subscriptionList', 'totalCount']),
    ...mapState('adminCommon', ['tableSelectionState']),
    ...mapState('user', ['userDetails']),
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    showCreate () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.subscription.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.subscription.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.subscription.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.subscription.currentPage = newPage
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions('subscriptions', ['getSubscriptionList', 'deleteSubscription']),
    labelsFun () {
      return this.subscriptionList.length ? Object.keys(this.subscriptionList[0]) : []
    },
    onClick (data) {
      // this.$router.push(`client/${data.client_id}`)
    },
    onUpdate (data) {
      this.subscriptionIdToUpdate = data.subscription_id
      this.updateSubscriptionModal = true
      // this.$router.push(`subscription/${data.subscription_id}/update`)
    },
    onCreate () {
      this.addSubscriptionModal = true
      // this.$router.replace(`/admin/client/${this.$route.params.id}/add/subscription`)
    },
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },
    updatePage (data) {
      this.currentPage = data
      this.getData()
    },
    updateLimit (data) {
      this.limit = data
      this.getData()
    },
    onChangeModal (value) {
      this.modal = value
    },
    onDelete (data) {
      this.deleteSubscriptionObject = data
      this.modal = true
    },
    async removeSubFun () {
      const res = await this.deleteSubscription(this.deleteSubscriptionObject.subscription_id)
      if (res === 200) {
        this.modal = false
        this.getData()
      }
    },
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    },
    getData () {
      this.getSubscriptionList({ clientId: this.$route.params.id, limit: this.limit, skip: (this.currentPage - 1) * this.limit, orderBy: this.sort, search: this.search })
    },
    handleUpdateModalChange (value) {
      this.updateSubscriptionModal = value
      if (!value) {
        this.getData()
      }
    },
    handleAddModalChange (value) {
      this.addSubscriptionModal = value
      if (!value) {
        this.getData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.clients-container {
  .user-delete-modal {
    display: flex;
    justify-content: center;
    .user-delete-inside {
      display: flex;
      color: var(--secondary-text-color);
      font-family: Quicksand;
      flex-direction: column;
      background: var(--dropdown-backgroud);
      border-radius: 4px;
      width: 400px;
      height: 200px;
      padding: 25px;
      .modal-data {
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    p {
      font-size: 14px;
    }
      }
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }

    }
    .actions {
      padding-top: 20px;
      text-align: end;
      .button {
        height: 32px;
        border: none;
        font-size: 14px;
        width: 75px;
      }
      .button:nth-child(1) {
        margin-left: 5px;
        background-color: transparent;
        color: var(--secondary-text-color);
        border: 1px solid var(--primary-border-color);
        &:hover {
          border: 2px solid var(--primary-border-color);
          transition: border 0.3s ease-out;
        }
        &:active {
          background-color: #7F8FA466;
          transition: background-color 0.3s ease-out;
        }
      }
      .button:nth-child(2) {
        margin-left: 5px;
        background-color: #FF0000;
        color: white;
        &:hover {
          background-color: #F64A4A;
          transition: background-color 0.3s ease-out;
        }
        &:active {
          background-color: #EE1111;
          transition: background-color 0.3s ease-out;
        }
      }
    }
  }
  .user-table {
        margin-bottom: 20px;

    background: var(--primary);
  }
}
</style>
