<template>
  <!-- <modal-vue
    @onChange="onChangeModal"
    :modal="modal"> -->
    <div class="new-clients-container">
      <admin-header-vue
        alias=""
        :close="true"
        @onClose="onClose"
        pageName="New User" />
      <form @submit.prevent="handleSubmit">
        <div class="new-client">
          <div class="columns">
            <div class="column">
              <upload-vue
                label="Avatar"
                :submitted="submitted"
                :required="false"
                :image="newClient.image"
                @onChangeImage="onChangeLightLogo" />
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <input-vue
                :required="true"
                :min="1"
                type="text"
                :max="150"
                :submitted="submitted"
                @onChangeName="onChangeFirstName"
                label="First Name" />
              <div v-if="submitted && isFirstNamePatternInvalid" class="required-error">{{namePatternErrorMessage}}</div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <input-vue
                :required="true"
                :min="1"
                type="text"
                :max="150"
                :submitted="submitted"
                @onChangeName="onChangeLastName"
                label="Last Name" />
              <div v-if="submitted && isLastNamePatternInvalid" class="required-error">{{namePatternErrorMessage}}</div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12 p-0">
              <div class="columns">
                <div class="column is-4">
                  <select-vue
                    :value="newClient.countryCode"
                    :required="false"
                    label="Country Code"
                    :submitted="submitted"
                    placeholder="Select"
                    :list="countryCode"
                    @onChangeSelection="onChangeCode"
                    id="dial_code"
                    name="name" />
                  <div
                    v-if="submitted && newClient.countryCode==null && ((newClient.phone && newClient.phone.length))"
                    class="custom-error">
                    Code is required
                  </div>
                </div>
                <div class="column is-8">
                  <input-vue
                    :required="false"
                    :min="1"
                    type="number"
                    :max="15"
                    :submitted="submitted"
                    @onChangeName="onChangePhone"
                    label="Phone" />
                  <div
                    v-if="submitted && !(newClient.phone && newClient.phone.length) && (newClient.phone==null ||  newClient.phone=='') && ((newClient.countryCode && newClient.countryCode.length))"
                    class="custom-error">
                    Phone is required
                  </div>
                  <div
                    v-if="submitted && (newClient.phone && newClient.phone.length && newClient.phone.length>15) && ((newClient.countryCode && newClient.countryCode.length))"
                    class="custom-error">
                    Phone must be at max 15 characters.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <input-vue
                :required="true"
                :min="1"
                type="email"
                :max="150"
                :submitted="submitted"
                @onChangeName="onChangeEmail"
                label="Email" />
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <input-vue
                :required="true"
                type="password"
                :max="100"
                :passwordReveal="true"
                :submitted="submitted"
                @onChangeName="onChangePassword"
                label="Password" />
              <div
                v-if="submitted && !isPasswordInvalid && passwordInvalidCode === 1"
                class="required-error">
                Length must be between 8 and 100 characters. Must contain one uppercase letter, one lowercase letter, and one digit.
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12 p-0">
              <div class="columns">
                <div class="column is-4">
                  <select-vue
                    :value="newClient.role"
                    :required="true"
                    label="Role"
                    :submitted="submitted"
                    placeholder="Select a role"
                    :list="allowedRoles"
                    @onChangeSelection="onChangeSelection"
                    id="r_id"
                    name="role" />
                </div>
                <div class="column is-6">
                  <select-vue
                    :value="newClient.status"
                    :required="true"
                    label="Status"
                    :submitted="submitted"
                    placeholder="Select a status"
                    :list="status"
                    @onChangeSelection="onChangeStatus"
                    id="id"
                    name="name" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="submit-button">
                <b-button @click.prevent="handleSubmit">Add</b-button>
                <b-button @click.prevent="onClose">Cancel</b-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  <!-- </modal-vue> -->
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { countryCode, status } from '../../../../constant/data'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'
// import ModalVue from '../../components/Modal.vue'
import SelectVue from '../../components/Select.vue'
import UploadVue from '../../components/Upload.vue'
import { re, allowedNamePattern } from '@/util/util'

export default {
  name: 'ClientAddUser',
  components: {
    AdminHeaderVue,
    InputVue,
    SelectVue,
    UploadVue
    // ModalVue
  },
  data () {
    return {
      // modal: true,
      status,
      countryCode,
      newClient: {
        firstName: null,
        lastName: null,
        file: null,
        image: null,
        email: null,
        password: null,
        phone: null,
        countryCode: null,
        role: null,
        clientId: null,
        invitedBy: null,
        status: null,
        imageName: null
      },
      submitted: false,
      isFirstNameInvalid: true,
      isLastNameInvalid: true,
      isFirstNamePatternInvalid: false,
      isLastNamePatternInvalid: false,
      isImageInvalid: true,
      isEmailInvalid: true,
      isPasswordInvalid: true,
      isRoleInvalid: true,
      isPhoneValid: true,
      isCodeValid: true,
      isStatusValid: true,
      passwordInvalidCode: null,
      namePatternErrorMessage: 'Please provide a valid input. Only letters, numbers, spaces, - and _ are allowed.'
    }
  },
  computed: {
    ...mapState('clients', ['clientList']),
    ...mapState('user', ['userDetails']),
    ...mapState('role', ['allowedRoles'])

  },
  mounted () {
    this.getAllowedRoles()

    this.newClient.clientId = this.$route.params.id
  },
  methods: {
    ...mapActions('clients', ['addClientUser']),
    ...mapActions('role', ['getAllowedRoles']),

    // onChangeModal (value) {
    //   this.modal = value
    //   if (!this.modal) {
    //     this.$router.replace(`/admin/client/${this.$route.params.id}/update`)
    //   }
    // },
    onClose () {
      this.$emit('close', false)
      // this.modal = false
    },
    onChangeLightLogo ({ file, name }, isValid) {
      this.newClient.image = file
      this.newClient.imageName = name
      this.isImageInvalid = isValid
    },
    onChangeFirstName (name, isInvalid) {
      this.newClient.firstName = name
      this.isFirstNameInvalid = isInvalid
      if (!isInvalid && !allowedNamePattern.test(name)) {
        this.isFirstNamePatternInvalid = true
      } else {
        this.isFirstNamePatternInvalid = false
      }
    },
    onChangeLastName (name, isInvalid) {
      this.newClient.lastName = name
      this.isLastNameInvalid = isInvalid
      if (!isInvalid && !allowedNamePattern.test(name)) {
        this.isLastNamePatternInvalid = true
      } else {
        this.isLastNamePatternInvalid = false
      }
    },
    onChangeEmail (name, isValid) {
      this.newClient.email = name
      this.isEmailInvalid = isValid
    },
    onChangePassword (name, isValid) {
      this.newClient.password = name
      this.isPasswordInvalid = isValid
      if (!re.test(this.newClient.password)) {
        this.passwordInvalidCode = 1
      } else {
        this.passwordInvalidCode = null
      }
    },
    onChangeSelection (name, isValid) {
      this.newClient.role = name
      this.isRoleInvalid = isValid
    },
    onChangePhone (name, isValid) {
      this.newClient.phone = name
      this.validatePhoneCode()
    },
    validatePhoneCode () {
      if (this.newClient.countryCode == null && ((this.newClient.phone && this.newClient.phone.length))) {
        this.isPhoneValid = true
      } else {
        this.isPhoneValid = false
      }
      if (!(this.newClient.phone && this.newClient.phone.length) && (this.newClient.phone === null || this.newClient.phone === '') &&
        ((this.newClient.countryCode && this.newClient.countryCode.length))) {
        this.isCodeValid = true
      } else if ((this.newClient.phone && this.newClient.phone.length && this.newClient.phone.length > 15) && ((this.newClient.countryCode && this.newClient.countryCode.length))) {
        this.isCodeValid = true
      } else {
        this.isCodeValid = false
      }
    },
    onChangeCode (name, isValid) {
      this.newClient.countryCode = name
      this.validatePhoneCode()
    },
    onChangeStatus (name, isValid) {
      this.newClient.status = name
      this.isStatusValid = isValid
    },
    handleSubmit () {
      this.newClient.invitedBy = this.userDetails.userId
      this.submitted = true
      if (
        !(
          this.isImageInvalid ||
          this.isFirstNameInvalid ||
          this.isLastNameInvalid ||
          this.isFirstNamePatternInvalid || 
          this.isLastNamePatternInvalid ||
          this.isEmailInvalid ||
          this.isPasswordInvalid ||
          this.isRoleInvalid ||
          this.isStatusValid ||
          this.isPhoneValid ||
          this.isCodeValid ||
          this.passwordInvalidCode !== null
        )
      ) {
        this.addClientUser(this.newClient).then(() => {
          this.onClose()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .new-clients-container {
    overflow-y: auto;
    background: var(--primary);
    border-radius: 4px;
    .new-client {
      .columns {
        margin: 0px;
        .column {
          .custom-error {
            position: absolute;
            color: red;
            position: absolute;
            font-size: 12px;
          }
          .upload {
            width: 100px;
            height: 100px;
            align-items: center;
            justify-content: center;
            border: 1px solid #dbdbdb;
            border-radius: 50%;
            img {
              width: 100px;
              height: 100px;
              object-fit: contain;
            }
          }
          .submit-button {
            display: flex;
            justify-content: flex-start;
            padding-top: 20px;
            button {
              height: 100%;
              padding: 5px 10px;
              background: #2780eb;
              color: white;
              border: none;
              margin-right: 10px;
            }
            button:nth-child(1) {
              margin-right: 5px;
            }
            button:nth-child(2) {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .column {
    position: relative;
    .required-error {
      position: absolute;
      color: red;
      font-size: 12px;
    }
  }
</style>
