<template>
  <div class="user-delete-modal">
    <div class="user-delete-inside">
      <p class="modal-header">Delete User</p>
      <div class="modal-data">
        <p>
          Are you sure you want to delete <b> ({{ user.email }}) </b>?
        </p>
        <div class="actions">
          <b-button @click="onClose">Cancel</b-button>
          <b-button @click="onDelete">Delete</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * The modal that pops up to confirm a delete action, when deleting users from the Admin Panel.
 * @displayName ClientDeleteUser
 * @author Mohammed Omar Siddiqui <mohammedomar.siddiqui@sganalytics.com>
 */
export default {
  name: 'ClientDeleteUser',
  props: {
    /**
     * The object containing the details of User to be deleted.
     */
    user: {
      type: Object
    }
  },
  methods: {
    /**
     * Gets called when the user clicks on close. Emits a value to ClientUsers.vue
     * @author Mohammed Omar Siddiqui <mohammedomar.siddiqui@sganalytics.com>
     */
    onClose () {
      this.$emit('close', false)
    },
    /**
     * Gets called when the user clicks on close. Emits a value to ClientUsers.vue
     * @author Mohammed Omar Siddiqui <mohammedomar.siddiqui@sganalytics.com>
     */
    onDelete () {
      this.$emit('delete', this.user)
    }
  }
}
</script>

<style lang="scss" scoped>
.user-delete-modal {
  display: flex;
  justify-content: center;
  color: var(--secondary-text-color);
  .user-delete-inside {
    display: flex;
    flex-direction: column;
    background: var(--dropdown-backgroud);
    border-radius: 4px;
    width: 324px;
    height: 194px;
    padding: 25px;
    .modal-data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      font-size: 14px;
      font-family: roboto;
    }
    .modal-header {
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 10px;
      text-align: left;
    }
  }
  .actions {
    padding-top: 10px;
    text-align: end;
    .button {
      height: 32px;
      border: none;
      font-size: 14px;
      width: 75px;
    }
    .button:nth-child(1) {
      margin-left: 5px;
      background-color: transparent;
      color: var(--secondary-text-color);
      border: 1px solid var(--primary-border-color);
      &:hover {
        border: 2px solid var(--primary-border-color);
        transition: border 0.3s ease-out;
      }
      &:active {
        background-color: #7f8fa466;
        transition: background-color 0.3s ease-out;
      }
    }
    .button:nth-child(2) {
      margin-left: 5px;
      background-color: #ff0000;
      color: white;
      &:hover {
        background-color: #f64a4a;
        transition: background-color 0.3s ease-out;
      }
      &:active {
        background-color: #ee1111;
        transition: background-color 0.3s ease-out;
      }
    }
  }
}
</style>
