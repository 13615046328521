<template>
  <div class="toggle-container">
    <p>{{label}}</p>
    <label class="switch">
      <input type="checkbox" :disabled="disabled" v-model="enable" />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>

/** This component renders the toggle switch used in the user dropdown menu.  */
export default {
  name: 'Toggle',
  props: ['toggle', 'label', 'onChange', 'disabled'],
  data () {
    return {
      enable: this.toggle
    }
  },
  watch: {
    enable () {
      this.$emit('onChange', this.enable)
    },
    toggle () {
      this.enable = this.toggle
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-container {
    display: flex;
    align-items: center;
    p {
        padding-right: 10px;
        font-family: Quicksand;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.21;
        margin: 0px;
        color: var(--user-dropdown-text-color);
    }
}
</style>
