<template>
     <div class="columns a-topic">
        <div class="column is-3">
            <div v-if="isEditable || topicGroup.isNew">
            <input-vue
                :value="topicGroup.topicAlias"
                :required="true"
                :showLable="false"
                :min="1"
                type="text"
                :max="150"
                placeholder="Parent Topic Alias"
                :submitted="localSubmitted"
                @onChangeName="onChangeTopicAlias"
                label="Parent Topic Alias" />
            </div>
            <p class="topic-label" v-else>{{topicGroup.topicAlias}}</p>
        </div>
        <div class="column is-7">
            <div v-if="isEditable || topicGroup.isNew" class="form-group">
                <dropdown-wrap-vue @onClickOutside="onClickOutside">
                  <div class="relative">
                    <b-input
                      type="search"
                      ref="topics"
                      @focus="onFocus"
                      :icon-right="openTopics ? 'menu-up' : 'menu-down'"
                      v-model="searchTopics"
                      class="form-control" />
                    <div
                      v-if="localSubmitted && !topicGroup.topicIds.length"
                      class="required-error">
                      Topic Name is required.
                    </div>
                    <common-selection-vue
                      :searchText="searchTopics"
                      name="topic_name"
                      @focus="onFocus"
                      :open="openTopics"
                      :selected="topicGroup.topicIds"
                      @onChange="onChangeTopics"
                      :showDataLink="true"
                      dataLinkLabel="category_name"
                      :list="categoryCustomTopics" />
                  </div>
                  <closable-tags-vue
                    @onChange="onRemoveTopicName"
                    name="topic_name"
                    :showDataLink="true"
                    dataLinkLabel="category_name"
                    :selected="topicGroup.topicIds" />
                </dropdown-wrap-vue>
              </div>
              <div v-else>
                  <closable-tags-vue
                    :canClose="false"
                    @onChange="onRemoveTopicName"
                    name="topic_name"
                    :showDataLink="true"
                    dataLinkLabel="category_name"
                    :selected="topicGroup.topicIds" />
              </div>
        </div>
        <div class="column is-2">
            <div v-if="isEditable || topicGroup.isNew" class="action-buttons">
            <b-button @click="action('update')"><div class="flex"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="invisible box"><path data-name="Rectangle 147804" fill="none" d="M0 0h22v22H0z"/></g><g data-name="icons Q2"><path data-name="Path 12820" d="M6.4 17.462l-3.721-3.673a.976.976 0 01-.093-1.255.883.883 0 011.395-.093l3.068 3.068 10.97-10.97a.93.93 0 011.3 1.3L7.7 17.462a.883.883 0 01-1.3 0z" fill="#fff"/></g></svg></div></b-button>
            <b-button @click="action('cancel')"><div class="flex"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="invisible box"><path data-name="Rectangle 147805" fill="none" d="M0 0h22v22H0z"/></g><g data-name="icons Q2"><path data-name="Path 12821" d="M12.338 11l5.07-5.018a1 1 0 00.1-1.29.908.908 0 00-1.434-.1l-5.066 5.066-5.066-5.066a.908.908 0 00-1.434.1 1 1 0 00.1 1.29L9.674 11l-5.066 5.018a1 1 0 00-.1 1.29.908.908 0 001.434.1l5.066-5.066 5.066 5.066a.908.908 0 001.434-.1 1 1 0 00-.1-1.29z" fill="#fff"/></g></svg></div></b-button>
            </div>
            <div v-else class="action-buttons">
            <b-button @click="action('edit')"><div class="flex"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.868 3.292L12.14.552a.962.962 0 00-1.357 0l-9.674 9.66-.883 3.812a.958.958 0 00.93 1.162 1 1 0 00.2 0l3.854-.883 9.66-9.65a.962.962 0 00-.002-1.361zM4.744 13.463l-3.612.758.823-3.542 7.238-7.21 2.788 2.789zm7.861-7.879l-2.79-2.789 1.618-1.608 2.743 2.789z" fill="#eff3f6" stroke="#eff3f6" stroke-width=".4"/></svg></div></b-button>
            <b-button @click="action('delete')"><div class="flex"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.316 2.211v-.737A1.474 1.474 0 008.842 0H4.421a1.474 1.474 0 00-1.474 1.474v.737H.737a.737.737 0 100 1.474h.737v8.105A2.211 2.211 0 003.684 14h5.895a2.211 2.211 0 002.211-2.211V3.684h.737a.737.737 0 000-1.474zm-1.474-.737H4.421v.737h4.421zm1.474 2.211H2.947v8.105a.737.737 0 00.737.737h5.895a.737.737 0 00.737-.737z" fill="#eff3f6" fill-rule="evenodd"/></svg></div></b-button>
            </div>
        </div>
        </div>
</template>

<script>
import { mapState } from 'vuex'
import DropdownWrapVue from '../../../../../components/Common/DropdownWrap.vue'
import CommonSelectionVue from '../../../../../components/Dropdowns/CommonSelection.vue'
import ClosableTagsVue from '../../../components/ClosableTags.vue'
import InputVue from '../../../components/Input.vue'

/** This component contains the code for the **Parent Topic Alias** rows on the *Standard + Custom* view of the **Update Subscription* and *New Subscription* modal views.  */
export default {
  components: {
    InputVue,
    DropdownWrapVue,
    CommonSelectionVue,
    ClosableTagsVue
  },
  // props: ['topicDetails', 'onUpdate', 'onDelete', 'submitted', 'onRemoveTopics', 'index', 'categoryCustomTopics'],
  props: {
    /** This prop contains the details of the new parent topic that will be created */
    topicDetails: Object,
    /** This prop defines the behavior for when the topic is updated */
    onUpdate: Function,
    /** This prop defines the behavior for when the topic is deleted */
    onDelete: Function,
    /** This prop indicates whether or not a form submission was attempted  */
    submitted: Boolean,
    /** This prop defines the behavior for when a topic is deselected from the list of child topics */
    onRemoveTopics: Function,
    /** This prop contains the index number of the parent topic */
    index: Number,
    /** This prop contains the list of topics (along with their correspoding categories) which can be grouped under this parent topic */
    categoryCustomTopics: Array
  },
  data () {
    return {
      localSubmitted: false,
      openTopics: false,
      searchTopics: null,
      isEditable: false,
      prevstate: {
        topicAlias: null,
        topicIds: [],
        isNew: false,
        index: null
      },
      topicGroup: {
        topicAlias: null,
        topicIds: [],
        isNew: false,
        index: null
      },
      isInValidTopicAlias: false
    }
  },
  watch: {
    topicDetails () {
      this.topicGroup = this.topicDetails
    },
    submitted () {
      this.localSubmitted = this.submitted
    },
    categoryCustomTopics () {
      this.fitlerParentId()
    }
  },
  mounted () {
    this.localSubmitted = this.submitted
    this.topicGroup = this.topicDetails
    this.topicGroup.index = this.index
    Object.assign(this.prevstate, this.topicGroup)
  },
  methods: {
    /** This method filters the functionality topics of the selected categories in the subscription, and shows those topics in the multiselect dropdown list for the parent topics
     * @public
     */
    fitlerParentId () {
      let child = []
      for (const selectedChild of this.topicGroup.topicIds) {
        const filter = this.categoryCustomTopics.filter(data => data.topic_id === selectedChild.topic_id)
        if (filter.length) {
          child = [...child, ...filter]
        }
      }
      this.topicGroup.topicIds = child
      Object.assign(this.prevstate, this.topicGroup)
    },
    /** This method updates the parent topic alias and emits an *onUpdate* event
     * @public
     */
    onChangeTopicAlias (name, isValid) {
      this.topicGroup.topicAlias = name
      this.isInValidTopicAlias = isValid
      this.$emit('onUpdate', this.topicGroup, this.index)
    },
    /** This method closes the mult-select dropdown when a click is detected anywhere outside its boundary
     * @public
     */
    onClickOutside () {
      this.openTopics = false
    },
    /** This method opens the multiselect dropdown when a click is detected on the input
     * @public
     */
    onFocus () {
      this.$refs.topics.$el.firstChild.focus()
      this.openTopics = true
    },
    /** This method updates the list of selected child topics and emits an *onUpdate* event
     * @public
     */
    onChangeTopics (ids, isValid) {
      this.topicGroup.topicIds = ids
      this.$emit('onUpdate', this.topicGroup, this.index)
    },
    /** This method is called when a user clicks on the X button on a topic tag present under the multiselect search dropdown. It emits *onUpdate* and *onRemoveTopics* events
     * @param object {Object} - The topic to be deselected/removed
     * @public
     */
    onRemoveTopicName (object) {
      this.topicGroup.topicIds = this.topicGroup.topicIds.filter(
        (id) => id.topic_id !== object.topic_id
      )
      this.$emit('onUpdate', this.topicGroup, this.index)
      this.$emit('onRemoveTopics', object, this.topicGroup.topicAliasId)
    },
    /** This method decides what action a particular button does depending on the value it receives.
     * @param type {String} - This parameter denotes the action that will take place when the button is clicked
     * @values edit, update, delete, cancel
     * @public
     */
    action (type) {
      switch (type) {
        case 'edit':
          this.isEditable = true
          break
        case 'update':
          if (!this.isInValidTopicAlias && this.topicGroup.topicIds.length) {
            if (this.topicGroup.isNew) {
              this.topicGroup.isNew = false
            }
            this.isEditable = false
            this.localSubmitted = false
            Object.assign(this.prevstate, this.topicGroup)
          } else {
            this.localSubmitted = true
          }
          break
        case 'delete':
          this.$emit('onDelete', this.topicGroup, this.index)
          break
        case 'cancel':
          if (this.topicGroup.isNew) {
            this.$emit('onDelete', this.topicGroup, this.index)
          } else {
            this.$emit('onUpdate', this.prevstate, this.index)
          }
          this.isEditable = false
          break
      }
    }
  }
}
</script>

<style lang="scss">
.columns {
    .column {
      .topic-label {
        font-size: 14px;
        font-family: Quicksand;
        color: #EFF3F6;
      }
        .relative {
            position: relative;
            .required-error {
              color: red;
              // position: absolute;
              font-size: 12px;
            }
        }
        .action-buttons {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 36px;
            .button {
                background-color: transparent;
                border: none;
                padding: 0px;
                height: auto;
                .flex {
                  display: flex;
                }
            }
            .button:nth-child(1) {
                margin-right: 10px;
            }
        }
    }
}
.a-topic {
        background-color: #1A1E25;
        border-radius: 4px;
    }
</style>
