<template>
  <div class="clients-container">
    <admin-header-vue
      v-if="showCreate && isEditable"
      alias="User"
      @onCreate="onCreate"
      pageName="" />
    <div class="user-table">
      <table-vue
        :labels="labels"
        :dateFormat="dateFormat"
        :data="clientUserList"
        :sortObj="sort"
        :totalCount="userTotalCount"
        :currentPage="currentPage"
        :limit="limit"
        :sortValidation="sortValidation"
        :canDelete="true"
        :titleData="'Users'"
        :showEditAction="showEdit"
        :showDeleteAction="showDelete"
        :showAction="(showEdit || showDelete) && isEditable "
        @searchText="searchText"
        @onUpdate="onUpdate"
        @onChangeSort="onChangeSort"
        @updatePage="updatePage"
        @updateLimit="updateLimit"
        @onDelete="onDelete" />
    </div>
    <modal-vue
      :modal="deleteUserModal"
      @onChange="handleDeleteUserModalChange">
      <delete-user
        v-if="userToDelete"
        @close="handleDeleteUserModalChange"
        :user=userToDelete
        @delete="deleteUser" />
    </modal-vue>
    <modal-vue
      :modal="addUserModal"
      @onChange="handleAddModalChange">
      <add-user @close="handleAddModalChange"></add-user>
    </modal-vue>
    <modal-vue
      :modal="UpdateUserModal"
      @onChange="handleUpdateModalChange">
      <update-user
        v-if="userIdToUpdate"
        @close="handleUpdateModalChange"
        :userId=userIdToUpdate></update-user>
    </modal-vue>
  </div>
</template>

<script>
/**
 * Users Section view in the Admin Panel
 * @displayName ClientUsers
 */
import { mapActions, mapState } from 'vuex'
import tableVue from '../../components/table.vue'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import ModalVue from '../../components/Modal.vue'
import AddUser from '../Clients/AddUser'
import UpdateUser from '../Clients/UpdateUser'
import DeleteUser from './DeleteUser.vue'

export default {
  name: 'ClientsUsers',
  components: {
    tableVue,
    AdminHeaderVue,
    ModalVue,
    AddUser,
    UpdateUser,
    DeleteUser
  },
  props: ['isEditable'],
  data () {
    return {
      search: null,
      labels: [{ name: 'First Name', alias: 'first_name' }, { name: 'Last Name', alias: 'last_name' }, { name: 'Email', alias: 'email' }, { name: 'Last Login', alias: 'last_login' }],
      dateFormat: ['last_login', 'created_at', 'updated_at'],
      sortValidation: ['first_name', 'last_name', 'email', 'role', 'last_login', 'created_at', 'updated_at'],
      sort: { by: 'asc', field: 'first_name' },
      addUserModal: false,
      UpdateUserModal: false,
      deleteUserModal: false,
      userIdToUpdate: null,
      userToDelete: {
        id: null,
        firstName: null,
        lastName: null,
        email: null
      },
      selectedCategories: []
    }
  },
  computed: {
    ...mapState('clients', ['clientUserList', 'userTotalCount']),
    ...mapState('adminCommon', ['tableSelectionState']),
    ...mapState('user', ['userDetails']),
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    showCreate () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.user.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.user.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.user.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.user.currentPage = newPage
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions('clients', ['getClientUser']),
    ...mapActions('invitation', ['removeUser']),
    labelsFun () {
      return this.clientUserList.length ? Object.keys(this.clientUserList[0]) : []
    },
    onUpdate (data) {
      this.userIdToUpdate = data.user_id
      this.UpdateUserModal = true
      // this.$router.replace('/admin/client/' + this.$route.params.id + '/user/' + data.user_id + '/update')
    },
    onCreate () {
      this.addUserModal = true
      // this.$router.replace('/admin/client/' + this.$route.params.id + '/add/user')
    },
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },
    updatePage (data) {
      this.currentPage = data
      this.getData()
    },
    updateLimit (data) {
      this.limit = data
      this.getData()
    },
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    },
    /**
     * When the delete button is clicked, the userToDelete object is filled with the details of the user about to be deleted. This object is then passed as a prop, and the modal is toggled on.
     * @author Mohammed Omar Siddiqui <mohammedomar.siddiqui@sganalytics.com>
     */
    onDelete (data, categories) {
      this.userToDelete.id = data.user_id
      this.userToDelete.firstName = data.first_name
      this.userToDelete.lastName = data.last_name
      this.userToDelete.email = data.email
      this.deleteUserModal = true
    },
    getData () {
      this.getClientUser({ clientId: this.$route.params.id, limit: this.limit, skip: (this.currentPage - 1) * this.limit, orderBy: this.sort, search: this.search })
    },
    handleUpdateModalChange (value) {
      this.UpdateUserModal = value
      if (!value) {
        this.getData()
      }
    },
    handleAddModalChange (value) {
      this.addUserModal = value
      if (!value) {
        this.getData()
      }
    },
    /**
     * This method toggles the userDeleteModal
     * @author Mohammed Omar Siddiqui <mohammedomar.siddiqui@sganalytics.com>
     */
    handleDeleteUserModalChange (value) {
      this.deleteUserModal = value
    },
    /**
     * When the user confirms the delete option, this method is called. It deletes the specified User from the database.
     * @author Mohammed Omar Siddiqui <mohammedomar.siddiqui@sganalytics.com>
     */
    async deleteUser (data) {
      this.deleteUserModal = false
      const res = await this.removeUser(data.id)
      if (res) {
        this.getData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .clients-container {
    .user-table {
      background: var(--primary);
      margin-bottom: 20px;
    }
  }
</style>
