<template>
  <div class="reports-configuration">
    <div class="label">Report Type</div>
    <div class="multiselect">
      <treeselect
        :multiple="true"
        :options="clientReportTypes"
        :searchable="false"
        @select="updateValue"
        @deselect="updateValue"
        :flat="true"
        placeholder="Select Report Type"
        v-model="selectedReport">
      </treeselect>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'ReportsConfiguration',
  components: {
    Treeselect
  },
  props: {
    clientDetails: {
      type: Object
    }
  },

  data () {
    return {
      selectedReport: null,
      initialLoad: true
    }
  },

  computed: {
    ...mapState('clients', ['clientReportTypes']),
    ...mapState('user', ['userDetails'])
  },
  watch: {
    clientDetails () {
      let reportsRepoModule = this.clientDetails.moduleId.find(module => module.module_name === 'reports-repository')
      if (reportsRepoModule && reportsRepoModule.properties && reportsRepoModule.properties.subTypes) {
        this.selectedReport = [...reportsRepoModule.properties.subTypes]
      }
    }
  },

  mounted () {
    this.getClientReportTypes()
  },

  methods: {
    ...mapActions('clients', ['getClientReportTypes', 'updateClientAccessToReportTypes']),
    ...mapActions('user', ['getUserDetails']),
    updateValue () {
      this.$nextTick(() => {
        const reportModule = this.userDetails.modules.find(
          (module) => module.module_name === 'reports-repository'
        )
        const reports = {
          clientId: this.$route.params.id,
          data: {
            subTypes: this.selectedReport,
            module_id: reportModule.module_id
          }
        }
        this.updateClientAccessToReportTypes(reports)
        this.getUserDetails()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reports-configuration {
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-direction: row;
  height: auto;
  justify-content: space-evenly;

  .label {
    font-size: 16px;
    color: var(--secondary-text-color);
    padding: 0.75rem 1rem;
    width: 30%;
  }
  .multiselect {
    width: 60%;
  }
}

::v-deep .vue-treeselect {
  width: 450px;
  .vue-treeselect__placeholder {
    font-family: roboto;
    font-size: 14px;
  }
  .vue-treeselect__control {
    background: transparent;
    border: none;
    border: var(--tertiary-border-color) !important;
    border-radius: 4px !important;
    color: var(--user-dropdown-text-color) !important;
    input {
      color: var(--user-dropdown-text-color) !important;
    }
    .vue-treeselect__value-container {
      .vue-treeselect__single-value {
        color: var(--user-dropdown-text-color) !important;
        font-family: roboto;
        font-size: 14px;
      }
    }
  }

  .vue-treeselect__menu {
    background: var(--dropdown-backgroud) !important;
    color: var(--secondary-text-color) !important;
    border: none;
    border: 1px solid lightgray;

    .vue-treeselect__option--disabled .vue-treeselect__label-container {
      cursor: not-allowed;
      color: #B1AAAA;
    }

    .vue-treeselect__option--highlight {
      background-color: var(--dropdown-background-category-hover) !important;
    }
    .vue-treeselect--open-below {
      background-color: var(--dropdown-background-category-hover) !important;
    }
  }
}
</style>
