<template>
    <div class="topic-bucket-container">
        <div class="columns">
        <div class="column header is-3">
            <p>Parent Topic</p>
        </div>
        <div class="column header is-3">
            <p>Topic Alias</p>
        </div>
        <div class="column header is-4">
            <p>Topics</p>
        </div>
        <div class="column header is-2">
            <p>Action</p>
        </div>
        </div>
        <div class="single-topic-row" v-for="(topic,index) of topicList" :key="index">
            <single-topic-row-vue :parentTopicList="parentTopicList" @onUpdate="onUpdate" :categoryCustomTopics="categoryCustomTopics" :submitted="submitted" @onDelete="onDeleteFun" @onRemoveTopics="onRemoveTopicsFun" :index="index" :topicDetails="topic" />
        </div>
        <div class="bucket-footer">
        <b-button @click="addNewTopicFun" class="add-new-topic"><p>+ Add New Alias</p></b-button>
        <ul v-if="pendingChildTopics.length">
          <li>{{filterData}}</li>
        </ul>
        </div>
    </div>
</template>

<script>
import SingleTopicRowVue from './SingleTopicRow.vue'

export default {
  components: {
    SingleTopicRowVue
  },
  props: ['topicList', 'addNewTopic', 'submitted', 'onDelete', 'onRemoveTopics', 'onUpdateTopic', 'categoryCustomTopics', 'parentTopicList', 'pendingChildTopics'],
  data () {
    return {
      topics: []
    }
  },
  computed: {
    filterData () {
      return this.pendingChildTopics.filter((data) => !data.used).map(data => `${data.category + ' - ' + data.name}`).join(', ')
    }
  },
  mounted () {
    if (!this.topicList.length) {
      this.$emit('addNewTopic', { topicAlias: null, topicIds: [], parentGroup: {}, isNew: true })
    }
  },
  methods: {
    addNewTopicFun () {
      this.$emit('addNewTopic', { topicAlias: null, topicIds: [], parentGroup: {}, isNew: true })
    },
    onUpdate (object, index) {
      this.$emit('onUpdateTopic', object, index)
    },
    onDeleteFun (object, index) {
      this.$emit('onDelete', object, index)
    },
    onRemoveTopicsFun (object, id) {
      this.$emit('onRemoveTopics', object, id)
    }
  }
}
</script>

<style lang="scss">
.topic-bucket-container {
  .columns {
    .header {
      padding: 10px 0px;
      p {
        font-size: 12px;
        font-family: Quicksand;
        font-weight: 500;
        color: #EFF3F6B2;
      }
    }
    .header:nth-child(4) {
      text-align: center;
    }
    .header:nth-child(2),.header:nth-child(3) {
      padding-left: 12px;
    }
  }
  padding: 0px 12px;
    .single-topic-row {
        padding: 5px 0px;
    }
    .bucket-footer {
      .add-new-topic {
        padding: 10px 0px 0px 0px;
        height: auto;
        background-color: transparent;
        border: none;
        p {
          font-size: 14px;
          color: #3C90F5;
          font-family:'Quicksand';
        }
    }
    ul {
      margin: 0px;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding-left: 20px;
      li {
        color: var(--description-light);
        font-size: 14px;
        font-family: 'ROBOTO';
        padding: 0px 2px;
        text-align: right;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    }
}
</style>
