<template>
  <!-- <modal-vue @onChange="onChangeModal" :modal="modal"> -->
    <div class="new-clients-container">
      <admin-header-vue alias="" :close="true" @onClose="onClose" pageName="Update User" />
      <form @submit.prevent="handleSubmit">
        <div class="new-client">
          <div class="columns">
            <div class="column is-12">
              <upload-vue
                label="Avatar"
                :submitted="submitted"
                :required="false"
                :image="newClient.avatar"
                @onChangeImage="onChangeLightLogo" />
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <input-vue
                :required="true"
                :min="1"
                :value="newClient.firstName"
                type="text"
                :max="150"
                :submitted="submitted"
                @onChangeName="onChangeFirstName"
                label="First Name" />
              <div v-if="submitted && isFirstNamePatternInvalid" class="required-error">{{namePatternErrorMessage}}</div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <input-vue
                :required="true"
                :min="1"
                :value="newClient.lastName"
                type="text"
                :max="150"
                :submitted="submitted"
                @onChangeName="onChangeLastName"
                label="Last Name" />
              <div v-if="submitted && isLastNamePatternInvalid" class="required-error">{{namePatternErrorMessage}}</div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12 p-0">
              <div class="columns">
                <div class="column is-4">
                  <select-vue
                    :value="newClient.countryCode"
                    :required="false"
                    label="Country Code"
                    :submitted="submitted"
                    placeholder="Select"
                    :list="countryCode"
                    @onChangeSelection="onChangeCode"
                    id="dial_code"
                    name="name" />
                    <div
                      v-if="submitted && newClient.countryCode==null && ((newClient.phone && newClient.phone.length))"
                      class="custom-error">
                      Code is required
                    </div>
                </div>
                <div class="column is-8">
                  <input-vue
                    :required="false"
                    :min="1"
                    :value="newClient.phone"
                    type="number"
                    :max="15"
                    :submitted="submitted"
                    @onChangeName="onChangePhone"
                    label="Phone" />
                    <div
                      v-if="submitted && !(newClient.phone && newClient.phone.length) && (newClient.phone==null ||  newClient.phone=='') && ((newClient.countryCode && newClient.countryCode.length))"
                      class="custom-error">
                      Phone is required
                    </div>
                    <div
                      v-if="submitted && (newClient.phone && newClient.phone.length && newClient.phone.length>15) && ((newClient.countryCode && newClient.countryCode.length))"
                      class="custom-error">
                      Phone must be at max 15 characters.
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <input-vue
                :required="true"
                :min="1"
                type="email"
                :max="150"
                :submitted="submitted"
                @onChangeName="onChangeEmail"
                :value="newClient.email"
                label="Email" />
            </div>
          </div>
          <div class="columns">
            <div class="column is-12 p-0">
              <div class="columns">
                <div v-if="showRole" class="column is-6">
                  <select-vue
                    :value="newClient.role"
                    :required="true"
                    label="Role"
                    placeholder="Select a role"
                    :list="allowedRoles"
                    :submitted="submitted"
                    @onChangeSelection="onChangeSelection"
                    name="role" />
                </div>
                <div class="column is-6">
                  <select-vue
                    :value="newClient.status"
                    :required="true"
                    label="Status"
                    :submitted="submitted"
                    placeholder="Select a status"
                    :list="status"
                    @onChangeSelection="onChangeStatus"
                    id="id"
                    name="name" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
                <span v-if="clientUserDetails.status === 2" class="required-error">Can't update user with pending invitation</span>
              <div class="submit-button">
                <b-button :disabled="clientUserDetails.status === 2" @click.prevent="handleSubmit">Update</b-button>
                <b-button @click.prevent="onClose">Cancel</b-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  <!-- </modal-vue> -->
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'
import SelectVue from '../../components/Select.vue'
import UploadVue from '../../components/Upload.vue'
// import ModalVue from '../../components/Modal.vue'
import { countryCode, status } from '../../../../constant/data'
import Snackbar from '../../../../components/Snackbar'
import { allowedNamePattern } from '@/util/util'

export default {
  name: 'ClientAddUser',
  components: {
    AdminHeaderVue,
    InputVue,
    SelectVue,
    UploadVue
    // ModalVue
  },
  props: ['userId'],
  data () {
    return {
      roles: [{ role_id: 2, role_name: 'Admin' }, { role_id: 3, role_name: 'User' }],
      status,
      // modal: true,
      countryCode,
      newClient: {
        userId: null,
        firstName: null,
        lastName: null,
        email: null,
        avatar: null,
        role: null,
        phone: null,
        countryCode: null,
        status: null,
        imageName: null
        // password: null,
      },
      submitted: false,
      isFirstNameInvalid: true,
      isLastNameInvalid: true,
      isFirstNamePatternInvalid: false,
      isLastNamePatternInvalid: false,
      isImageInvalid: false,
      isEmailInvalid: true,
      // isPasswordInvalid: false,
      isRoleInvalid: true,
      isPhoneValid: true,
      isCodeValid: true,
      isStatusValid: true,
      namePatternErrorMessage: 'Please provide a valid input. Only letters, numbers, spaces, - and _ are allowed.'
    }
  },
  computed: {
    ...mapState('clients', ['clientUserDetails']),
    ...mapState('role', ['allowedRoles']),
    showRole () {
      // return !!this.allowedRoles.filter(data => Number(data.r_id) === this.clientUserDetails.role)[0]
      return !!this.allowedRoles.find(data => Number(data.r_id) === this.clientUserDetails.role)
    }

  },
  watch: {
    clientUserDetails () {
      const {
        firstName,
        lastName,
        avatar,
        email,
        countryCode,
        phone,
        status
      } = this.clientUserDetails
      this.newClient.firstName = firstName
      this.newClient.lastName = lastName
      this.newClient.avatar = avatar
      this.filterRoleFun()
      this.newClient.email = email
      this.newClient.phone = phone
      this.newClient.countryCode = countryCode
      this.newClient.status = status
      this.newClient.imageName = null
    },
    allowedRoles () {
      this.filterRoleFun()
    }
  },
  mounted () {
    this.getAllowedRoles()
    this.newClient.userId = this.userId // this.$route.params.user_id
    this.getUserDetailsByClient(this.userId)
  },
  methods: {
    ...mapActions('clients', ['updateClientUser', 'getUserDetailsByClient']),
    ...mapActions('role', ['getAllowedRoles']),
    filterRoleFun () {
      const filterRole = this.allowedRoles.filter(data => Number(data.r_id) === this.clientUserDetails.role)
      if (filterRole.length) {
        this.newClient.role = filterRole[0]
      } else {
        this.newClient.role = {
          r_id: this.clientUserDetails.role,
          role: this.clientUserDetails.roleName
        }
      }
    },
    onChangeLightLogo ({ file, name }, isValid) {
      this.newClient.avatar = file
      this.newClient.imageName = name
      this.isImageInvalid = isValid
    },
    // onChangeModal (value) {
    //   this.modal = value
    //   if (!this.modal) {
    //     this.$router.replace(
    //       `/admin/client/${this.$route.params.client_id}/update`
    //     )
    //   }
    // },
    onClose () {
      this.$emit('close', false)
      // this.modal = false
    },
    onChangeFirstName (name, isInvalid) {
      this.newClient.firstName = name
      this.isFirstNameInvalid = isInvalid
      if (!isInvalid && !allowedNamePattern.test(name)) {
        this.isFirstNamePatternInvalid = true
      } else {
        this.isFirstNamePatternInvalid = false
      }
    },
    onChangeLastName (name, isInvalid) {
      this.newClient.lastName = name
      this.isLastNameInvalid = isInvalid
      if (!isInvalid && !allowedNamePattern.test(name)) {
        this.isLastNamePatternInvalid = true
      } else {
        this.isLastNamePatternInvalid = false
      }
    },
    onChangeEmail (name, isValid) {
      this.newClient.email = name
      this.isEmailInvalid = isValid
    },
    // onChangePassword (name, isValid) {
    //   this.newClient.password = name
    //   this.isPasswordInvalid = isValid
    // },
    onChangeSelection (name, isValid) {
      this.newClient.role = name
      this.isRoleInvalid = isValid
    },
    onChangePhone (name, isValid) {
      this.newClient.phone = name
      if (this.newClient.phone !== null && this.newClient.phone.length === 0) {
        this.newClient.phone = null
      }
      this.validatePhoneCode()
    },
    onChangeCode (name, isValid) {
      this.newClient.countryCode = name
      this.validatePhoneCode()
    },
    validatePhoneCode () {
      if (this.newClient.countryCode == null && ((this.newClient.phone && this.newClient.phone.length))) {
        this.isPhoneValid = true
      } else {
        this.isPhoneValid = false
      }
      if (!(this.newClient.phone && this.newClient.phone.length) && (this.newClient.phone === null || this.newClient.phone === '') &&
        ((this.newClient.countryCode && this.newClient.countryCode.length))) {
        this.isCodeValid = true
      } else if ((this.newClient.phone && this.newClient.phone.length && this.newClient.phone.length > 15) && ((this.newClient.countryCode && this.newClient.countryCode.length))) {
        this.isCodeValid = true
      } else {
        this.isCodeValid = false
      }
    },
    onChangeStatus (name, isValid) {
      this.newClient.status = name
      this.isStatusValid = isValid
    },
    checkChanges () {
      for (const field in this.clientUserDetails) {
        if (this.clientUserDetails[field] !== this.newClient[field]) {
          return false
        }
      }
      return true
    },
    handleSubmit (e) {
      this.submitted = true
      this.validatePhoneCode()
      if (!this.checkChanges()) {
        if (
          !(
            this.isImageInvalid ||
            this.isFirstNameInvalid ||
            this.isLastNameInvalid ||
            this.isFirstNamePatternInvalid || 
            this.isLastNamePatternInvalid ||
            this.isEmailInvalid ||
            (this.isRoleInvalid && this.showRole) ||
            this.isStatusValid ||
            this.isPhoneValid ||
            this.isCodeValid
          )
        ) {
          this.updateClientUser(this.newClient).then(() => {
            this.onClose()
          })
        }
      } else {
        Snackbar({ message: 'No Updates Found.', type: 'is-danger' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-clients-container {
    background: var(--primary);
    border-radius: 4px;
  .new-client {
    .columns {
      margin: 0px;
      .column {
        .custom-error {
          position: absolute;
          color: red;
          position: absolute;
          font-size: 12px;
        }
        .upload {
          width: 100px;
          height: 100px;
          align-items: center;
          justify-content: center;
          border: 1px solid #dbdbdb;
          border-radius: 50%;
          img {
            width: 100px;
            height: 100px;
            object-fit: contain;
          }
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
