<template>
  <div class="new-clients-container">
    <!-- <admin-header-vue alias="" pageName="Update Organization" /> -->
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column">
            <div class="display-flex">
              <upload-vue
                label="Light Logo"
                :disabled="!isEditable"
                :submitted="submitted"
                :required="true"
                :image="newClient.properties.logo_light"
                @onChangeImage="onChangeLightLogo" />
              <upload-vue
                label="Dark Logo"
                :disabled="!isEditable"
                :submitted="submitted"
                :required="false"
                :image="newClient.properties.logo_dark"
                @onChangeImage="onChangeDarkLogo" />
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column form-group">
            <input-vue
              :value="newClient.name"
              :required="true"
              :min="1"
              :disabled="!isEditable"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeName"
              label="Organization Name" />
          </div>
          <div class="column form-group">
            <div class="relative">
              <input-vue
                :value="newClient.slug"
                :required="true"
                :min="1"
                :disabled="!isEditable"
                type="text"
                :max="150"
                :readonly="!isEditableLocal"
                :submitted="submitted"
                @onChangeName="onChangeSlug"
                label="Slug (iNava Domain)" />
              <div v-if="isEditable" class="set-right-button">
                <label class="display-flex-center">
                  <input type="checkbox" v-model="isEditableLocal" />
                  <p>Edit?</p>
                </label>
              </div>
            </div>
          </div>
          <div class="column form-group">
            <input-vue
              :value="newClient.siteUrl"
              :required="false"
              :disabled="!isEditable"
              :min="1"
              type="url"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeUrl"
              label="Website URL" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <div class="form-group">
              <p class="display-flex">Owned Products</p>
              <dropdown-wrap-vue @onClickOutside="onClickOutside">
                <div class="relative">
                  <b-input
                    type="search"
                    ref="products"
                    :disabled="!isEditable"
                    @focus="onFocus"
                    :icon-right="openProduct ? 'menu-up' : 'menu-down'"
                    v-model="searchProduct"
                    class="form-control" />
                  <!-- <div
                    v-if="submitted && !newClient.productId.length"
                    class="required-error">
                    Owned Product Name is required
                  </div> -->
                  <common-selection-vue
                    :searchText="searchProduct"
                    name="product_name"
                    @focus="onFocus"
                    :open="openProduct"
                    :selected="newClient.productId"
                    @onChange="onChangeProductId"
                    :list="productList" />
                </div>
                <closable-tags-vue
                  :canClose="isEditable"
                  @onChange="onRemoveProductName"
                  name="product_name"
                  :selected="newClient.productId" />
              </dropdown-wrap-vue>
            </div>
          </div>
          <div class="column is-4">
            <calendar-vue
              label="Subscription Start"
              :value="newClient.startSubscription"
              :submitted="submitted"
              :disabled="!isEditable"
              :required="true"
              :max="newClient.endSubscription"
              @onChange="onChangeStart"
              placeholder="" />
          </div>
          <div class="column is-4">
            <calendar-vue
              label="Subscription End"
              :disabled="!isEditable"
              :value="newClient.endSubscription"
              @onChange="onChangeEnd"
              :min="newClient.startSubscription"
              :required="true"
              :submitted="submitted"
              placeholder="" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <div class="form-group">
              <p class="display-flex">Allowed Email Domains</p>
              <div class="relative">
                <b-input
                  type="search"
                  :disabled="!isEditable"
                  ref="emailDomains"
                  @keydown.native.enter="addDomain"
                  @focus="onFocusEmailDomains"
                  v-model="emailDomain"
                  class="form-control" />
                <div
                  v-if="clicked && isDomainValid"
                  class="required-error relative">
                  Domain is invalid.
                </div>
              </div>
              <closable-tags-vue
                :canClose="isEditable"
                @onChange="onRemoveAllowedDomains"
                :selected="newClient.properties.allowed_email_domains" />
            </div>
          </div>
          <div class="column is-2">
            <div class="form-group">
              <p class="display-flex">Show powered by</p>
              <b-field>
                <b-switch
                  :disabled="!isEditable"
                  :value="newClient.properties.showPoweredByText"
                  @input="onChangeShowPoweredByText"
                  type="is-info">
                </b-switch>
              </b-field>
            </div>
          </div>
          <div class="column form-group is-6">
            <input-vue
              :value="newClient.properties.poweredByText"
              :required="false"
              :min="1"
              type="text"
              :max="40"
              :disabled="!isEditable"
              @onChangeName="onChangePoweredByText"
              :submitted="submitted"
              label="Powered by text" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :value="newClient.properties.teamName"
              :required="false"
              :disabled="!isEditable"
              type="input"
              @onChangeName="onChangeTeamName"
              :submitted="submitted"
              label="Team Name" />
          </div>
        </div>
        <div class="columns">
          <div class="column form-group is-4">
            <input-vue
              :value="newClient.properties.platformIntroText"
              :required="false"
              :min="1"
              :disabled="!isEditable"
              type="textarea"
              @onChangeName="onChangePlatformIntroText"
              :submitted="submitted"
              label="Platform introduction text" />
          </div>
          <div class="column">
            <div class="display-flex">
              <upload-vue
                label="Product Logo Light"
                :submitted="submitted"
                :required="false"
                :disabled="!isEditable"
                :image="newClient.properties.productLogoLight"
                @onChangeImage="onChangeProductLogoLight" />
              <upload-vue
                label="Product Logo Dark"
                :submitted="submitted"
                :required="false"
                :disabled="!isEditable"
                :image="newClient.properties.productLogoDark"
                @onChangeImage="onChangeProductLogoDark" />
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Module Settings">
              <div class="display-flex">
                <div class="column is-6 p-0">
                  <p class="display-flex">Subscribed Modules</p>
                  <dropdown-wrap-vue @onClickOutside="onClickOutsideModule">
                    <div class="relative">
                      <b-input
                        type="search"
                        ref="modules"
                        :disabled="!isEditable"
                        @focus="onFocusModule"
                        :icon-right="openModule ? 'menu-up' : 'menu-down'"
                        v-model="searchModule"
                        class="form-control" />
                      <common-selection-vue
                        :searchText="searchModule"
                        name="module_name"
                        @focus="onFocusModule"
                        :open="openModule"
                        :selected="newClient.moduleId"
                        @onChange="onChangeModuleId"
                        :list="moduleList" />
                    </div>
                    <closable-tags-vue
                      :canClose="isEditable"
                      @onChange="onRemoveModuleName"
                      name="module_name"
                      :selected="newClient.moduleId" />
                  </dropdown-wrap-vue>
                </div>
              </div>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="column-headers is-flex">
              <div class="module-names">Module</div>
              <div class="Custom-names">Custom Name</div>
            </div>
            <div v-for="module, index of userDetails.modules.map((m) => m.module_name)" :key="index" class="map-item">
              <div class="module-name">{{ moduleMap[module] }}</div>
              <input-vue
                :value="newClient.properties.moduleMap[module]"
                :required="false"
                type="input"
                @onChangeName="
                  (name, isValid) => onChangeMapping(name, isValid, module)
                "
                :submitted="submitted" />
            </div>
          </div>
        </div>
        <!-- <div class="columns">
          <div class="column">
            <div class="display-flex">
              <upload-vue
                label="Login vendor logo"
                :submitted="submitted"
                :required="true"
                :image="newClient.properties.productLogoLight"
                @onChangeImage="onChangeProductLogoLight" />
              <upload-vue
                label="Sidebar vendor Logo"
                :submitted="submitted"
                :required="false"
                :image="newClient.properties.productLogoDark"
                @onChangeImage="onChangeProductLogoDark" />
            </div>
          </div>
        </div> -->
        <div v-if="isEditable" class="columns">
          <div class="column">
            <div class="submit-button form-group">
              <b-button @click.prevent="handleSubmit">Update</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import calendarVue from '../../components/calendar.vue'
import InputVue from '../../components/Input.vue'
import UploadVue from '../../components/Upload.vue'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'
import CommonSelectionVue from '../../../../components/Dropdowns/CommonSelection.vue'
import ClosableTagsVue from '../../components/ClosableTags.vue'
import Snackbar from '../../../../components/Snackbar'
import moment from 'moment'
import { validateDomain } from '../../../../util/util'
import { moduleMap } from '@/constant/data'
import { isEqual } from 'lodash'

export default {
  name: 'UpdateClient',
  components: {
    InputVue,
    calendarVue,
    UploadVue,
    DropdownWrapVue,
    CommonSelectionVue,
    ClosableTagsVue
  },
  props: ['isEditable'],
  data () {
    return {
      newClient: {
        startSubscription: null,
        endSubscription: null,
        name: null,
        properties: {
          logo_light: null,
          logo_dark: null,
          allowed_email_domains: [],
          productLogoLight: null,
          productLogoDark: null,
          showPoweredByText: true,
          poweredByText: null,
          platformIntroText: null,
          teamName: null,
          moduleMap: {}
        },
        lightLogoName: null,
        darkLogoName: null,
        productLogoLightName: null,
        productLogoDarkName: null,
        siteUrl: null,
        slug: null,
        clientId: null,
        productId: [],
        moduleId: [],
        deletedProductId: [],
        deletedModuleId: []
      },
      clicked: false,
      isDomainValid: false,
      deletedAllowedDomains: [],
      emailDomain: null,
      submitted: false,
      isValidOrganizationName: true,
      isValidTeamName: false,
      isValidUrl: false,
      isValidSlug: true,
      isLightLogoValid: false,
      isDarkLogoValid: false,
      isStarSubscriptionDateValid: true,
      isEndSubscriptionDateValid: true,
      isEditableLocal: false,
      openProduct: false,
      openModule: false,
      searchProduct: null,
      searchModule: null,
      isProductLogoLightValid: true,
      isProductLogoDarkValid: true,
      moduleMap
    }
  },
  computed: {
    ...mapState('clients', ['clientDetails']),
    ...mapState('products', ['productList']),
    ...mapState('user', ['userDetails']),
    ...mapState('modules', ['moduleList'])
  },
  watch: {
    clientDetails () {
      const {
        subscription_end: endSubscription,
        subscription_start: startSubscription,
        site_url: siteUrl,
        properties,
        client_slug: slug,
        client_name: name,
        productId,
        moduleId,
        show_powered_by_text,
        powered_by_text,
        platform_intro_text
      } = this.clientDetails
      this.newClient.name = name
      this.newClient.properties.logo_light = properties.logo_light
      this.newClient.properties.logo_dark = properties.logo_dark
      this.newClient.slug = slug
      this.newClient.startSubscription = new Date(startSubscription)
      this.newClient.endSubscription = new Date(endSubscription)
      this.newClient.siteUrl = siteUrl
      this.newClient.productId = productId
      this.newClient.moduleId = moduleId
      this.newClient.deletedProductId = []
      this.newClient.deletedModuleId = []
      this.newClient.lightLogoName = null
      this.newClient.darkLogoName = null
      this.newClient.productLogoLightName = null
      this.newClient.productLogoDarkName = null
      this.newClient.properties.teamName = properties.teamName || null
      this.newClient.properties.productLogoLight =
        properties.product_logo_light || null
      this.newClient.properties.productLogoDark =
        properties.product_logo_dark || null
      this.newClient.properties.showPoweredByText =
        properties.show_powered_by_text !== undefined
          ? properties.show_powered_by_text
          : true
      this.newClient.properties.moduleMap = properties.module_map
        ? { ...properties.module_map }
        : {}
      this.newClient.properties.poweredByText = properties.powered_by_text
      this.newClient.properties.platformIntroText =
        properties.platform_intro_text
      if (properties.allowed_email_domains !== undefined) {
        this.newClient.properties.allowed_email_domains = JSON.parse(
          JSON.stringify(properties.allowed_email_domains)
        )
      } else {
        this.newClient.properties.allowed_email_domains =
          properties.allowed_email_domains || []
      }
      this.deletedAllowedDomains = []
      this.getUserDetails()
    },
    emailDomain () {
      if (validateDomain.test(this.emailDomain)) {
        this.isDomainValid = false
      } else {
        this.isDomainValid = true
      }
    }
  },
  mounted () {
    this.newClient.clientId = this.$route.params.id
    this.getProductList()
    this.getModuleList()
    this.getSingleClientDetails(this.$route.params.id)
  },
  methods: {
    ...mapActions('clients', ['getSingleClientDetails', 'updateClient']),
    ...mapActions('products', ['getProductList']),
    ...mapActions('modules', ['getModuleList']),
    ...mapActions('user', ['getUserDetails']),

    onChangeStart (date, isValid) {
      this.newClient.startSubscription = date
      this.isStarSubscriptionDateValid = isValid
    },
    onChangeEnd (date, isValid) {
      this.newClient.endSubscription = date
      this.isEndSubscriptionDateValid = isValid
    },
    onChangeName (name, isValid) {
      this.newClient.name = name
      this.isValidOrganizationName = isValid
    },
    onChangeUrl (name, isValid) {
      this.newClient.siteUrl = name
      this.isValidUrl = isValid
    },
    onChangeTeamName (name, isValid) {
      this.newClient.properties.teamName = name
      this.isValidTeamName = isValid
    },
    onChangeLightLogo ({ file, name }, isValid) {
      this.newClient.properties.logo_light = file
      this.newClient.lightLogoName = name
      this.isLightLogoValid = isValid
    },
    onChangeDarkLogo ({ file, name }, isValid) {
      this.newClient.properties.logo_dark = file
      this.newClient.darkLogoName = name
      this.isDarkLogoValid = isValid
    },
    onChangeProductLogoLight ({ file, name }, isValid) {
      this.newClient.properties.productLogoLight = file
      this.newClient.productLogoLightName = name
      this.isProductLogoLightValid = isValid
    },
    onChangeProductLogoDark ({ file, name }, isValid) {
      this.newClient.properties.productLogoDark = file
      this.newClient.productLogoDarkName = name
      this.isProductLogoDarkValid = isValid
    },
    onChangeSlug (name, isValid) {
      this.newClient.slug = name
      this.isValidSlug = isValid
    },
    generateSlug (name) {
      return name
        .toString()
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '')
    },
    onClickOutside () {
      this.openProduct = false
    },
    onClickOutsideModule () {
      this.openModule = false
    },
    onChangeProductId (name, isValid) {
      this.newClient.productId = name
    },
    onChangeModuleId (name, isValid) {
      this.newClient.moduleId = name
    },
    onChangeMapping (name, isValid, module) {
      if (name !== null) {
        this.newClient.properties.moduleMap[module] = name.trim()
      }
    },
    onRemoveProductName (object) {
      this.newClient.productId = this.newClient.productId.filter(
        (id) => id.product_id !== object.product_id
      )
    },
    onRemoveModuleName (object) {
      this.newClient.moduleId = this.newClient.moduleId.filter(
        (id) => id.module_id !== object.module_id
      )
    },
    onFocus () {
      this.$refs.products.$el.firstChild.focus()
      this.openProduct = true
    },
    onFocusModule () {
      this.$refs.modules.$el.firstChild.focus()
      this.openModule = true
    },
    addDomain () {
      this.clicked = true
      if (this.emailDomain !== null && this.emailDomain.length) {
        const check = this.newClient.properties.allowed_email_domains.filter(
          (domain) => domain.toLowerCase() === this.emailDomain.toLowerCase()
        )
        if (!check.length) {
          if (validateDomain.test(this.emailDomain)) {
            this.newClient.properties.allowed_email_domains.push(
              this.emailDomain
            )
            this.emailDomain = null
            this.isDomainValid = false
            this.clicked = false
          } else {
            this.isDomainValid = true
          }
        }
      }
    },
    onFocusEmailDomains () {
      this.$refs.emailDomains.$el.firstChild.focus()
    },
    onRemoveAllowedDomains (object) {
      this.newClient.properties.allowed_email_domains =
        this.newClient.properties.allowed_email_domains.filter(
          (id) => id !== object
        )
      this.deletedAllowedDomains.push(object)
    },
    checkChanges () {
      if (this.clientDetails.client_name !== this.newClient.name) {
        return false
      } else if (
        moment(new Date(this.clientDetails.subscription_start)).format(
          'DD/MM/YYYY'
        ) !==
        moment(new Date(this.newClient.startSubscription)).format('DD/MM/YYYY')
      ) {
        return false
      } else if (
        moment(new Date(this.clientDetails.subscription_end)).format(
          'DD/MM/YYYY'
        ) !==
        moment(new Date(this.newClient.endSubscription)).format('DD/MM/YYYY')
      ) {
        return false
      } else if (this.clientDetails.site_url !== this.newClient.siteUrl) {
        return false
      } else if (
        this.clientDetails.properties.logo_light !==
        this.newClient.properties.logo_light
      ) {
        return false
      } else if (
        this.clientDetails.properties.logo_dark !==
        this.newClient.properties.logo_dark
      ) {
        return false
      } else if (
        !isEqual(
          this.clientDetails.properties.module_map,
          this.newClient.properties.moduleMap
        )
      ) {
        return false
      } else if (this.clientDetails.client_slug !== this.newClient.slug) {
        return false
      } else if (this.newClient.deletedProductId.length) {
        return false
      } else if (this.newClient.deletedModuleId.length) {
        return false
      } else if (
        this.clientDetails.productId.length !== this.newClient.productId.length
      ) {
        return false
      } else if (
        this.clientDetails.moduleId.length !== this.newClient.moduleId.length
      ) {
        return false
      } else if (this.deletedAllowedDomains.length) {
        return false
      }
      if (this.clientDetails.properties.product_logo_light !== undefined) {
        if (
          this.clientDetails.properties.product_logo_light !==
          this.newClient.properties.productLogoLight
        ) {
          return false
        }
      } else {
        if (this.newClient.properties.productLogoLight !== null) {
          return false
        }
      }
      if (this.clientDetails.properties.product_logo_dark !== undefined) {
        if (
          this.clientDetails.properties.product_logo_dark !==
          this.newClient.properties.productLogoDark
        ) {
          return false
        }
      } else {
        if (this.newClient.properties.productLogoDark !== null) {
          return false
        }
      }
      if (this.clientDetails.properties.show_powered_by_text !== undefined) {
        if (
          this.clientDetails.properties.show_powered_by_text !==
          this.newClient.properties.showPoweredByText
        ) {
          return false
        }
      } else {
        if (this.newClient.properties.showPoweredByText === false) {
          return false
        }
      }
      if (this.clientDetails.properties.powered_by_text !== undefined) {
        if (
          this.clientDetails.properties.powered_by_text !==
          this.newClient.properties.poweredByText
        ) {
          return false
        }
      } else {
        if (this.newClient.properties.poweredByText !== undefined) {
          return false
        }
      }
      if (this.clientDetails.properties.platform_intro_text !== undefined) {
        if (
          this.clientDetails.properties.platform_intro_text !==
          this.newClient.properties.platformIntroText
        ) {
          return false
        }
      } else {
        if (this.newClient.properties.platformIntroText !== undefined) {
          return false
        }
      }
      if (this.clientDetails.properties.allowed_email_domains !== undefined) {
        if (
          this.clientDetails.properties.allowed_email_domains.length !==
          this.newClient.properties.allowed_email_domains.length
        ) {
          return false
        }
      } else if (this.newClient.properties.allowed_email_domains.length) {
        return false
      }
      if (
        this.clientDetails.properties.teamName !==
        this.newClient.properties.teamName
      ) {
        return false
      }
      return true
    },
    handleSubmit (e) {
      this.submitted = true
      this.newClient.deletedProductId = []
      for (const product of this.clientDetails.productId) {
        const check = this.newClient.productId.filter(
          (data) => data.product_id === product.product_id
        )
        if (!check.length) {
          this.newClient.deletedProductId.push(product.product_id)
        }
      }
      this.newClient.deletedModuleId = []
      for (const module of this.clientDetails.moduleId) {
        const check = this.newClient.moduleId.filter(
          (data) => data.module_id === module.module_id
        )
        if (!check.length) {
          this.newClient.deletedModuleId.push(module.module_id)
        }
      }
      if (!this.checkChanges()) {
        if (
          !(
            this.isValidOrganizationName ||
            this.isValidUrl ||
            this.isValidSlug ||
            this.isDarkLogoValid ||
            this.isLightLogoValid ||
            this.isStarSubscriptionDateValid ||
            this.isEndSubscriptionDateValid
          )
        ) {
          const object = {
            startSubscription: moment(this.newClient.startSubscription).format(
              'YYYY-MM-DD'
            ),
            endSubscription: moment(this.newClient.endSubscription).format(
              'YYYY-MM-DD'
            ),
            name: this.newClient.name,
            properties: this.newClient.properties,
            siteUrl: this.newClient.siteUrl,
            slug: this.newClient.slug,
            productId: this.newClient.productId.map(
              (object) => object.product_id
            ),
            moduleId: this.newClient.moduleId.map((object) => object.module_id),
            clientId: this.newClient.clientId,
            deletedProductId: this.newClient.deletedProductId,
            deletedModuleId: this.newClient.deletedModuleId,
            lightLogoName: this.newClient.lightLogoName,
            darkLogoName: this.newClient.darkLogoName,
            productLogoLightName: this.newClient.productLogoLightName,
            productLogoDarkName: this.newClient.productLogoDarkName
          }
          this.updateClient(object)
        }
      } else {
        Snackbar({ message: 'No Updates Found.', type: 'is-danger' })
      }
    },
    handleCancel () {
      this.$router.push('/admin/client')
    },
    onChangePlatformIntroText (value) {
      this.newClient.properties.platformIntroText = value
    },
    onChangePoweredByText (value) {
      this.newClient.properties.poweredByText = value
    },
    onChangeShowPoweredByText (value) {
      this.newClient.properties.showPoweredByText = value
    }
  }
}
</script>

<style lang="scss" scoped>
.new-clients-container {
  .new-client {
    background: var(--primary);
    border-radius: 4px;
    .columns {
      margin: 0px;
      .column {
        // border: 1px solid grey;
        .display-flex {
          margin: 0px;
          display: flex;
          color: #7f8fa4;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          line-height: 20px;
          .astrik {
            color: red;
          }
        }
        .relative {
          position: relative;

          .required-error {
            color: red;
            position: absolute;
            font-size: 12px;
          }
          .relative {
            position: relative;
          }
        }
        .column-headers {
          font-weight: 500;
          margin-bottom: 15px;
          .module-names {
            width: 25%;
          }
        }
        .map-item {
          display: flex;
          margin-bottom: 15px;
          .module-name {
            width: 25%;
            font-size: 14px;
          }
          .form-group {
            width: 25%;
          }
        }

        .set-right-button {
          position: absolute;
          top: 0px;
          right: 0px;
          .display-flex-center {
            display: flex;
            align-items: center;
            p {
              padding-left: 5px;
              margin: 0px;
              color: #7f8fa4;
              font-size: 12px;
              font-family: Quicksand;
              display: flex;
              line-height: 20px;
            }
          }
        }
        .display-flex .upload-container:nth-child(2) {
          padding-left: 20px;
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
