<template>
  <div class="update-client-container">
    <b-collapse
      class="card"
      animation="slide"
      :open="isOpen == 0"
      @open="isOpen = 0">
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">
            {{ `${isEditable?'Update':'View'} Organization` }}
          </p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <update-client-vue :isEditable="isEditable" />
        </div>
      </div>
    </b-collapse>
    <b-collapse
      class="card"
      animation="slide"
      :open="isOpen == 1"
      @open="isOpen = 1">
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">
            {{ 'Configurations' }}
          </p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <b-tabs class="block" v-model="activeTab">
            <b-tab-item label="Users">
              <client-users-vue :isEditable="isEditable" />
            </b-tab-item>
            <b-tab-item label="Data Subscriptions">
              <subscriptions-vue :isEditable="isEditable" />
            </b-tab-item>
            <b-tab-item label="News Configuration">
              <ClientConfiguration  />
            </b-tab-item>
            <b-tab-item label="Reports Configurations">
              <ReportsConfiguration  :clientDetails="clientDetails" />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SubscriptionsVue from '../Subscriptions/Subscriptions.vue'
import ClientUsersVue from './ClientUsers.vue'
import UpdateClientVue from './UpdateClient.vue'
import ClientConfiguration from '@/views/Admin/views/Clients/ClientConfiguration.vue'
import ReportsConfiguration from '@/views/Admin/views/Clients/ReportsConfiguration.vue'

export default {
  name: 'UpdateClientContainer',
  components: {
    UpdateClientVue,
    ClientUsersVue,
    SubscriptionsVue,
    ClientConfiguration,
    ReportsConfiguration
  },
  data () {
    return {
      isOpen: 0,
      activeTab: 0
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('clients', ['clientDetails']),
    isEditable () {
      const obj = [{ name: 'update', alias: 'update' }, { name: 'view', alias: 'read' }]
      const check = this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path))
      if (check.length) {
        const result = obj.filter((data) => this.$router.history.current.path.includes(data.name))
        if (result.length) {
          if (result[0].alias === 'update') {
            return true
          }
        }
      }
      return false
    }
  },
}
</script>

<style lang="scss" scoped>
.update-client-container {
  padding: 20px 0px;
.card {
  background: var(--primary);
  overflow: visible;
  .card-header-title {
    color: var(--secondary-text-color);
  }
  .collapse-content {
      .card-content {
          padding: 10px 10px;
      }
  }
}
}
</style>
