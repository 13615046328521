<template>
    <input
    type="radio"
    :disabled="disable"
    @click="changeFun()"
    :value="value"
    v-model="localSelected" />
</template>

<script>
/** This component is for the radio inputs found on the admin panel */
export default {
  // props: ['selected', 'value', 'onChange', 'disable'],
  props: {
    selected: Object,
    value: Object,
    /** This prop defines the behavior to be followed when an option is selected */
    onChange: Function,
    /** This prop indicates whether an option is selectable or not */
    disable: Boolean
  },
  data () {
    return {
      localSelected: this.selected
    }
  },
  watch: {
    selected () {
      this.localSelected = this.selected
    }
  },
  methods: {
    changeFun () {
      this.$emit('onChange', this.value)
    }
  }
}
</script>

<style lang="scss">

</style>
